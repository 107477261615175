import React, { useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [searchParams] = useSearchParams(); // Hook to access query params
  const uid = searchParams.get("uid"); // Get uid from query param
  const token = searchParams.get("token"); // Get token from query param
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false); // Toggle for password visibility
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false); // Toggle for confirm password visibility
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();

  const validateForm = () => {
    let formErrors = {};
    if (!password) {
      formErrors.password = "Password is required.";
    } else if (password.length < 6) {
      formErrors.password = "Password must be at least 6 characters.";
    }
    if (!confirmPassword) {
      formErrors.confirmPassword = "Confirm Password is required.";
    } else if (password !== confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match.";
    }
    return formErrors;
  };

  const handleResetPassword = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
        new_password:password,
        new_password2:confirmPassword
    }

    setIsLoading(true);
    try {
        console.log('checkece', uid,'/n', token)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-password/${uid}/${token}/`,
        payload
      );
      if(response.status===200 || response.status===201){
          toast({
            title: "Password Reset Successful",
            description: "You can now log in with your new password.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          // Clear form fields after success
          setPassword("");
          setConfirmPassword("");
          navigate("/login");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast({
        title: "Error",
        description: "Failed to reset password. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center mx-0  min-h-screen bg-gray-100">
      <div className="w-full max-w-sm max-w-lg p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
          Reset Password
        </h2>

        {/* Password Field */}
        <FormControl isInvalid={errors.password} mb={4}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter new password"
              size="lg"
              focusBorderColor="blue.500"
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={isPasswordVisible ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                aria-label={
                  isPasswordVisible ? "Hide password" : "Show password"
                }
              />
            </InputRightElement>
          </InputGroup>
          {errors.password && (
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          )}
        </FormControl>

        {/* Confirm Password Field */}
        <FormControl isInvalid={errors.confirmPassword} mb={6}>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={isConfirmPasswordVisible ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your new password"
              size="lg"
              focusBorderColor="blue.500"
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={isConfirmPasswordVisible ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() =>
                  setIsConfirmPasswordVisible(!isConfirmPasswordVisible)
                }
                aria-label={
                  isConfirmPasswordVisible
                    ? "Hide confirm password"
                    : "Show confirm password"
                }
              />
            </InputRightElement>
          </InputGroup>
          {errors.confirmPassword && (
            <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
          )}
        </FormControl>

        {/* Reset Button */}
        <Button
          isLoading={isLoading}
          loadingText="Resetting Password"
          colorScheme="orange"
          size="lg"
          width="full"
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default ResetPassword;
