import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { User, Lock, Mail, ArrowRight } from 'lucide-react';
import AuthFormWrapper from './authFormWrapper';
import StyledButton from '../ui/styledButton';
import StyledInput from '../ui/styledInput';

// Registration Screen
const RegisterScreen = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!username) newErrors.username = 'Username is required';
    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
          },
          body: JSON.stringify({
            username,
            email,
            password,
            password2: confirmPassword
          })
        });

        const data = await response.json();

        if (response.ok) {
          // Registration successful
          navigate('/login');
        } else {
          // Handle errors from the API
          const newErrors = {};
          if (data.username) newErrors.username = data.username[0];
          if (data.email) newErrors.email = data.email;
          if (data.password) newErrors.password = data.password[0];
          if (data.password2) newErrors.confirmPassword = data.password2[0];
          setErrors(newErrors);
        }
      } catch (error) {
        // Handle network errors
        setErrors({ general: 'An error occurred. Please try again.' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <AuthFormWrapper title="Create an account" subtitle="Join our community today">
      <form className="mt-8 space-y-6" onSubmit={handleRegister}>
        <div>
          <StyledInput
            icon={User}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            error={errors.username}
            required
          />
          <StyledInput
            icon={Mail}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            error={errors.email}
            required
          />
          <StyledInput
            icon={Lock}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            error={errors.password}
            required
          />
          <StyledInput
            icon={Lock}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm password"
            error={errors.confirmPassword}
            required
          />
        </div>
        {errors.general && (
          <div className="text-red-500 text-sm">{errors.general}</div>
        )}
        <div>
          <StyledButton type="submit" isLoading={isLoading}>
            Register
            <ArrowRight className="ml-2 h-4 w-4" />
          </StyledButton>
        </div>
      </form>
      <div className="text-center mt-6">
        <Link to="/login" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
          Already have an account? Sign in
        </Link>
      </div>
    </AuthFormWrapper>
  );
};

export default RegisterScreen;