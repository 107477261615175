import React, { useState, useRef } from 'react';

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const recognitionRef = useRef(null);

  // Handle starting the audio recording
  const startRecording = () => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Your browser does not support Speech Recognition');
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      setIsRecording(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInputMessage(transcript);
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error', event.error);
    };

    recognition.onend = () => {
      setIsRecording(false);
    };

    recognitionRef.current = recognition;
    recognition.start();
  };

  // Handle stopping the audio recording
  const stopRecording = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
    }
    setIsRecording(false);
  };

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      setMessages([...messages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      {/* Chat header */}
      <div className="bg-blue-600 text-white p-4 shadow-md">
        <h2 className="text-xl font-semibold">Chat Page</h2>
      </div>

      {/* Chat body */}
      <div className="flex-grow p-4 overflow-y-auto mt-32">
        <div className="space-y-4">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
            >
              <div
                className={`${
                  msg.sender === 'user' ? 'bg-blue-500' : 'bg-gray-300'
                } text-white p-3 rounded-lg max-w-xs break-words`}
              >
                {msg.text}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Chat input and controls */}
      <div className="p-4 bg-white shadow-lg flex items-center space-x-2">
        <button
          onMouseDown={startRecording}
          onMouseUp={stopRecording}
          className={`p-2 rounded-full ${isRecording ? 'bg-red-500' : 'bg-blue-500'} text-white`}
        >
          {isRecording ? 'Recording...' : '🎤'}
        </button>

        <input
          type="text"
          className="flex-grow border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder={isRecording ? "Release mic to make transcript" :"Type your message..."}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
        />

        <button
          onClick={handleSendMessage}
          className="bg-blue-600 text-white p-2 rounded-lg"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatPage;
