import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_API_URL}/api/`,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      }
      return headers;
    },
   }),
  endpoints: (builder) => ({
    getProjects: builder.query({
      query: () => 'projects/',
    }),
    getProjectById: builder.query({
      query: (id) => `projects/${id}/`,
    }),
    createProject: builder.mutation({
      query: (newProject) => ({
        url: 'create-project/',
        method: 'POST',
        body: newProject,
      }),
    }),
    getChatHistory: builder.query({
      query: (projectId) => `chat-history/?id=${projectId}`,
    }),
    // Add more endpoints as needed
  }),
});

export const { useGetProjectsQuery, useGetProjectByIdQuery, useCreateProjectMutation, useGetChatHistoryQuery } = apiSlice;
export default apiSlice;
