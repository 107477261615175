import React, { useEffect, useState } from "react";
import { MapPinHouse } from "lucide-react";

const GooglePlacesAutocomplete = ({ value, onChange, error, required, placeholder }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Load Google Maps JavaScript API script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCMTEo5sZvMi170FzDQTKctk1uTAf20KYM&libraries=places`;
    script.async = true;
    script.onload = initAutocomplete;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const initAutocomplete = () => {
    const autocompleteInstance =
      new window.google.maps.places.AutocompleteService();
    setAutocomplete(autocompleteInstance);
  };

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setInputValue(value);
    onChange(value);

    if (value.length > 2 && autocomplete) {
      setIsLoading(true);
      try {
        const response = await autocomplete.getPlacePredictions({
          input: value,
          types: ["address"],
          componentRestrictions: { country: "us" }, // Restrict to US addresses
        });
        setSuggestions(response.predictions || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      }
      setIsLoading(false);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion.description);
    onChange(suggestion.description);
    setSuggestions([]);
  };

  return (
    <div className="relative">
      <div className="relative">
        <MapPinHouse className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className={`h-11 w-full rounded-lg border bg-white pl-10 pr-4 text-sm focus:outline-none focus:ring-2 ${
            error
              ? "border-red-500 focus:ring-red-500"
              : "border-gray-200 focus:ring-blue-500"
          }`}
          placeholder={placeholder}
          required={required}
        />
      </div>

      {isLoading && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg border border-gray-200 shadow-lg">
          <div className="p-2 text-sm text-gray-500">Loading...</div>
        </div>
      )}

      {suggestions.length > 0 && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg border border-gray-200 shadow-lg">
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.place_id}
              className="p-2 hover:bg-gray-100 cursor-pointer text-sm"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion.description}
            </div>
          ))}
        </div>
      )}

      {error && <p className="mt-1 text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default GooglePlacesAutocomplete;
