import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Lock, User, ArrowRight } from "lucide-react";
import { Alert, AlertDescription } from "../ui/alert";
import AuthFormWrapper from "./authFormWrapper";
import StyledButton from "../ui/styledButton";
import StyledInput from "../ui/styledInput";

// Login Screen
const LoginScreen = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!username) newErrors.username = "Username is required";
    if (!password) newErrors.password = "Password is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth/login/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
            body: JSON.stringify({
              username,
              password,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          // Login successful
          localStorage.setItem("accessToken", data.access);
          localStorage.setItem("refreshToken", data.refresh);
          navigate("/dashboard"); // Adjust this to your app's main page after login
        } else {
          // Handle errors from the API
          setErrors({ form: "Invalid username or password" });
        }
      } catch (error) {
        // Handle network errors
        setErrors({ form: "An error occurred. Please try again." });
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <AuthFormWrapper title="Welcome back" subtitle="Sign in to your account">
      <form className="mt-8 space-y-6" onSubmit={handleLogin}>
        {errors.form && (
          <Alert variant="destructive">
            <AlertDescription>{errors.form}</AlertDescription>
          </Alert>
        )}
        <div>
          <StyledInput
            icon={User}
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            error={errors.username}
            required
          />
          <StyledInput
            icon={Lock}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            error={errors.password}
            required
          />
        </div>
        <div>
          <StyledButton type="submit" isLoading={isLoading}>
            Sign in
            <ArrowRight className="ml-2 h-4 w-4" />
          </StyledButton>
        </div>
      </form>
      <div className="flex items-center justify-between mt-6">
        <Link
          to="/register"
          className="text-sm font-medium text-primary hover:underline"
        >
          Create account
        </Link>
        <Link
          to="/password-reset"
          className="text-sm font-medium text-primary hover:underline"
        >
          Forgot password?
        </Link>
      </div>
    </AuthFormWrapper>
  );
};

export default LoginScreen;
