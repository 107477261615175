import React from 'react';

// Reusable styled input component with animation and validation
const StyledInput = ({ icon: Icon, error, placeholder, className, ...props }) => (
  <div className={`relative mb-4 group ${className}`}>
    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
      <Icon className={`h-5 w-5 ${error ? 'text-red-400' : 'text-gray-400 group-focus-within:text-indigo-500'} transition-colors duration-200`} aria-hidden="true" />
    </div>
    <input
      className={`block w-full pl-10 pr-3 py-2 border ${error ? 'border-red-300' : 'border-gray-300'} rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:ring-2 ${error ? 'focus:ring-red-500 focus:border-red-500' : 'focus:ring-indigo-500 focus:border-indigo-500'} sm:text-sm transition duration-200 ease-in-out`}
      placeholder={placeholder}
      {...props}
    />
    {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
  </div>
);

export default StyledInput;