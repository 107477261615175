import { File, FileSpreadsheet, Upload, X } from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const ProjectActionsModal = ({ isOpen, onClose, projectName, generateReport, reportLoading }) => {
  const { t } = useTranslation();

  useEffect(() => {
    // Prevent background scroll when the modal is open
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-end justify-center">
      {/* Overlay*/}
      <div className="fixed inset-0 w-[90%] mx-auto " onClick={onClose} />

      {/* Modal content*/}
      <div className="relative w-full md:w-2/5 bg-blue-950 text-white rounded-t-lg p-6 mb">
        {/* Modal Header */}
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-center flex-grow">
            {projectName}
          </h2>
          <button className="text-white" onClick={onClose}>
            <X />
          </button>
        </div>

        <hr className="border-white mb-4" />

        {/* Modal Actions */}
        <div className="flex flex-col space-y-2">
          {/* <button className="flex items-center p-2 bg-transparent border border-white rounded hover:bg-gray-700">
            <Upload className="mr-2" size={16} />
            {t("reprocessUpdatedInfo")}
          </button> */}
          <button onClick={generateReport} className="flex items-center p-2 bg-transparent border border-white rounded hover:bg-gray-700">
            <File className="mr-2" size={16} />
            {reportLoading ? (
              <>
                {t("Generating...")}
                <div className="inline-block ml-2 animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
              </>
            ) : t("generateScopeOfWork")}
          </button>
          {/* <button className="flex items-center p-2 bg-transparent border border-white rounded hover:bg-gray-700">
            <FileSpreadsheet className="mr-2" size={16} />
            {t("createBidSpreadsheet")}
          </button> */}
        </div>
      </div>
    </div>
  );
};
