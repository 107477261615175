import React, { createContext, useState, useEffect } from "react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    eng: {
      translation: {
        welcome: "Welcome",
        projects: "Projects",
        account: "Account",
        signout: "Sign Out",
        projectsDashboard: "Projects Dashboard",
        totalProjects: "Total Projects",
        grayTemplates: "Gray Templates",
        beigeTemplates: "Beige Templates",
        quality:'Quality',
        qualityA: "Quality A",
        yourProjects: "Your Projects",
        newProject: "New Project",
        new:'New',
        project:'Project',
        status: "Status",
        inProgress: "In Progress",
        completed: "Completed",
        project: "Project",
        details: "Details",
        createdOn: "Created on",
        lastModified: "Last modified",
        addressDetails: "Address Details", 
        qualityMaterial: "Quality Material",
        colorTemplate: "Color Template",
        yearBuilt: "Year Built",
        squareFootage: "Square Footage",
        bedrooms: "Bedrooms",
        bathrooms: "Bathrooms",
        propertyType: "Property Type",
        grays:'Grays',
        jumpTo: "Jump to",
        projectSetup: "Project Setup",
        interiorSections: "Interior Sections",
        demolition: "Demolition",
        foundation: "Foundation",
        framingAndCarpentry: "Framing and Carpentry",
        reprocessUpdatedInfo: "Reprocess With Updated Information",  
        generateScopeOfWork: "Generate Scope Of Work",
        createBidSpreadsheet: "Create Bid Generation SpreadSheet",
        startNewProject:"Start New Project",
        projectStarted:"Project Started",
        my:'My',
        updateProfile:'Update Profile',
        changePassword:'Change Password',
        signOut:'Sign out',
        whatIsTheAddress:'What is the address?',
        pleaseProvideRoomDetailsManually:'Please provide room details manually.',
        confirmInformCount:'Confirm information provided has the correct bed and bath count?',
        typeYourMessage:'Type your message...',
        recordHiToInitiate:`Please record 'Hi' to initiate the chat`,
        recordToReply:'Record to reply...',
        back:'Back',
        next:'Next',
        skip:'Skip',
        continueChat:'Continue chat',
        oldPassword:'Old password',
        newPassword:'New Password',
        confirmNewPassword:'Confirm new Password',
        enterNewPassword:'Enter new password',
        enterOldPassword:'Enter old password',
        cancel:'Cancel',
        oldestFirst:"Oldest First",
        newestFirst:"Newest First",
        nameAZ:"Name A-Z",
        nameZA:"Name Z-A",
        recentlyModified:"Recently Modified",
        search:"Search",
        holdAudioToRecord:"Hold to record audio",
        holdVideoToRecord:"Hold to record video",
        createNewProject:"Create New Project",
        startYourNewProject:"Start your new project",
        selectColorTemplate:"Select Color Template",
        selectQuality:"Select Quality",
        selectPropertyType:"Select Property Type",
        enterDetailsManually:"Enter details manually",
        enterAddress:"Enter address",
        projectName:"Project Name",
        squareFootage:"Square Footage",
        totalBedrooms:"Total Bedrooms",
        totalBathrooms:"Total Bathrooms",
        numberOfStories:"Number of Stories",
        yearBuilt:"Year Built",
        createProject:"Create Project",
        residential:"Residential",
        commercial:"Commercial",
        confirmProjectDetails:"Confirm Project Details",
        stories:"Stories",
        editAddress:"Edit Address",
        confirmCreateProject:"Confirm and Create",
        finish:"Finish Scope",
        continue:"Continue",
      },
    },
    spa: {
      translation: {
        welcome: "Bienvenido",
        projects: "Proyectos",
        account: "Cuenta",
        signout: "Cerrar sesión",
        projectsDashboard: "Panel de Proyectos",
        totalProjects: "Proyectos totales",
        beigeTemplates: "Plantillas beige",
        quality:'Calidad',
        qualityA: "Calidad A",
        yourProjects: "Tus Proyectos",
        newProject: "Nuevo Proyecto",
        new:'Nuevo',
        project:'Proyecto',
        status: "Estado",
        inProgress: "En Curso",
        completed: "Terminada",
        project: "Proyecto",
        details: "Detalles",
        createdOn: "Creado el",
        lastModified: "Última modificación",
        addressDetails: "Detalles de la dirección", 
        qualityMaterial: "Material de calidad",
        colorTemplate: "Plantilla de color",
        yearBuilt: "Año de construcción",
        squareFootage: "Metros cuadrados",
        bedrooms: "Dormitorios",
        bathrooms: "Baños",
        propertyType: "Tipo de propiedad",
        grays:'Grises',
        jumpTo: "Saltar a",
        projectSetup: "Configuración del proyecto",
        interiorSections: "Secciones interiores",
        demolition: "Demolición",
        foundation: "Cimentación",
        framingAndCarpentry: "Estructura y carpintería",
        reprocessUpdatedInfo: "Reprocesar con información actualizada",   
        generateScopeOfWork: "Generar alcance del trabajo",
        createBidSpreadsheet: "Crear hoja de cálculo para generación de ofertas",
        startNewProject:'Iniciar Nuevo Proyecto',
        projectStarted:"Proyecto iniciado",
        my:'Mi',
        updateProfile:'Actualizar perfil',
        changePassword:'Cambiar la contraseña',
        signOut:'Desconectar',
        whatIsTheAddress:'Cual es la direccion?',
        pleaseProvideRoomDetailsManually:'Proporcione los detalles de la habitación manualmente.',
        confirmInformCount:'Confirma que la información proporcionada tiene el número correcto de camas y baños?',
        typeYourMessage:'Escribe tu mensaje...',
        recordHiToInitiate:`Por favor grabe 'Hola' para iniciar el chat.`,
        recordToReply:'Grabar para responder...',
        back:'Atrás',
        next:'Próximo',
        skip:'Saltar',
        continueChat:'Continuar charlando',
        oldPassword:'Contraseña anterior',
        newPassword:'Nueva contraseña',
        confirmNewPassword:'Confirmar nueva contraseña',
        enterNewPassword:'Ingrese una nueva contraseña',
        enterOldPassword:'Ingrese la contraseña anterior',
        cancel:'Cancelar',
        oldestFirst:"Mas antiguo primero",
        newestFirst:"Mas nuevo primero",
        nameAZ:"Nombre A-Z",
        nameZA:"Nombre Z-A",
        recentlyModified:"Recientemente modificado",
        search:"Buscar",
        holdAudioToRecord:"Mantén presionado para grabar audio",
        holdVideoToRecord:"Mantén presionado para grabar video",
        createNewProject:"Crear nuevo proyecto",
        startYourNewProject:"Inicia tu nuevo proyecto",
        selectColorTemplate:"Selecciona el color de la plantilla",
        selectQuality:"Selecciona la calidad",
        selectPropertyType:"Selecciona el tipo de propiedad",
        enterDetailsManually:"Ingresa los detalles manualmente",
        enterAddress:"Ingresa la direccion",
        projectName:"Nombre del proyecto",
        squareFootage:"Metros cuadrados",
        totalBedrooms:"Total de dormitorios",
        totalBathrooms:"Total de baños",
        numberOfStories:"Numero de pisos",
        yearBuilt:"Año de construccion",
        createProject:"Crear proyecto",
        residential:"Residencial",
        commercial:"Comercial",
        confirmProjectDetails:"Confirmar detalles del proyecto",
        stories:"Pisos",
        editAddress:"Editar direccion",
        confirmCreateProject:"Confirmar y crear",
        finish:"Terminar Scope",
        continue:"Continuar",
      },
    },
  },
  lng: "eng", 
  fallbackLng: "eng", 
  interpolation: {
    escapeValue: false,
  },
});

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("eng");

  useEffect(() => {
    i18next.changeLanguage(language);
  }, [language]);

  const changeLanguage = (lng) => {
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
