import { useState, useCallback } from 'react';
import OpenAI from 'openai';

const openai = new OpenAI({
//   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  apiKey: `${process.env.REACT_APP_OPENAI_API}`,
  dangerouslyAllowBrowser: true
});

const ASSISTANT_ID = `${process.env.REACT_APP_ASSISTANT_API}`;

const useChatAssistant = () => {
  const [thread, setThread] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const createThread = useCallback(async () => {
    try {
      const newThread = await openai.beta.threads.create();
      setThread(newThread);
      return newThread;
    } catch (err) {
      setError('Failed to create thread');
      console.error(err);
    }
  }, []);

  const sendMessage = useCallback(async (message) => {
    let threadId = thread?.id;
    let currentThread = thread;
    if (!threadId) {
      const newThread = await createThread();
      threadId = newThread?.id;
      currentThread = newThread;
    }
    try {
      await openai.beta.threads.messages.create(
        threadId,
        { role: 'user', content: message }
      );
      return currentThread;
    } catch (err) {
      setError('Failed to send message');
      console.error(err);
      return null;
    }
  }, [thread, createThread]);

  const runAssistant = useCallback(async (currentThread) => {
    if (!thread && !currentThread) return;
    setIsLoading(true);
    try {
      const run = await openai.beta.threads.runs.create(
        thread?.id || currentThread?.id,
        { assistant_id: ASSISTANT_ID }
      );
      let runStatus = await openai.beta.threads.runs.retrieve(thread?.id || currentThread?.id, run.id);
      while (runStatus.status !== 'completed') {
        await new Promise(resolve => setTimeout(resolve, 1000));
        runStatus = await openai.beta.threads.runs.retrieve(thread?.id || currentThread?.id, run.id);
      }
    } catch (err) {
      setError('Failed to run assistant');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [thread]);

  const getAssistantResponse = useCallback(async (currentThread) => {
    if (!thread && !currentThread) return null;
    try {
      const messages = await openai.beta.threads.messages.list(thread?.id || currentThread?.id);
      const assistantMessages = messages.data.filter(message => message.role === 'assistant');
      return assistantMessages.length > 0 ? assistantMessages[0].content[0].text.value : null;
    } catch (err) {
      setError('Failed to get assistant response');
      console.error(err);
      return null;
    }
  }, [thread]);

  const chat = useCallback(async (message) => {
    const currentThread = await sendMessage(message);
    await runAssistant(currentThread);
    return await getAssistantResponse(currentThread);
  }, [sendMessage, runAssistant, getAssistantResponse]);

  return {
    chat,
    isLoading,
    error,
    createThread,
  };
};

export default useChatAssistant;
