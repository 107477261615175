import { MicrophoneIcon, StopIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import { ArrowUp, Loader } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate, useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import ProjectDetails from "./Projects/ProjectDetails/ProjectDetails";
import useHandleSessionExpired from "../utils/useHandleSessionExpired";
import useShowToast from "../utils/useShowToast";
import { useTranslation } from "react-i18next";

//for translation
const sentenceMap = {
  'What is the address?': 'whatIsTheAddress',
  'Please provide room details manually': 'pleaseProvideRoomDetailsManually',
  'Confirm information provided has the correct bed and bath count?': 'confirmInformCount',
};

function ChatUI() {
  const navigate = useNavigate();
  const handleSessionExpired = useHandleSessionExpired();
  const showToast = useShowToast();
  const { t } = useTranslation(); 
  const [messages, setMessages] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [conversationResp, setConversationResp] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const recognitionRef = useRef(null);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);

  const translateMessage = (content) => {
    return sentenceMap[content] ? t(sentenceMap[content]) : content;
  };

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.log("Web Speech API is not supported in this browser.");
      return;
    }

    const SpeechRecognition = window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      const currentTranscript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");

      setInput(currentTranscript);
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error", event.error);
    };

    recognitionRef.current = recognition;
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    adjustTextareaHeight();
  }, [messages, input]);

  const handleToggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    recognitionRef.current.start();
    setIsRecording(true);
  };

  const stopRecording = () => {
    recognitionRef.current.stop();
    setIsRecording(false);
  };
  async function fetchChat() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat-questionare?id=${ProjectDetails.id}`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.status === 401) {
        navigate("/login");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }

      const data = await response.json();
      // setProjects(data);
      console.log("chat>>>", data);
    } catch (err) {
      // setError(err.message);
    } finally {
      // setLoading(false);
    }
  }

  // useEffect(() => {
  //   if (projectDetails?.id) {
  //     fetchChat();
  //   }
  // }, [projectDetails]);

  const handleSendMessage = async () => {
    if (!input.trim()) return;
    setIsLoading(true);
    if (isRecording) {
      stopRecording();
    }

    const userMessage = { role: "user", content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput(""); // Clear the input

    // Reset textarea height
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.value = '32423423';
    }
    let payload;
    if (!conversationResp) {
      payload = { message: input }; 
    } else {
      payload = {
        ...conversationResp, 
        message: input,
        id: id,
      };
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/chat-questionaire/`,
        payload,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      const assistantMessage = {
        role: "assistant",
        content: response.data.message,
      };
      setMessages((prev) => [...prev, assistantMessage]);
      setConversationResp(response.data);
      setThreadId(response.data.thread_id);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error sending message:", error);
      if (error?.response?.status === 401) {
        handleSessionExpired();
      }
      else{
        showToast('Something went wrong!', 'warning')
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    // adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const renderMessage = (message) => {
    const messageClass =
      message.role === "user"
        ? "border-2 text-gray-600 font-semibold"
        : "border-2 text-gray-600 shadow";

    return (
      <div className={`p-3 rounded-lg ${messageClass}`}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            h1: ({ node, ...props }) => (
              <h1 className="text-2xl font-bold mb-2" {...props} />
            ),
            h2: ({ node, ...props }) => (
              <h2 className="text-xl font-bold mb-2" {...props} />
            ),
            h3: ({ node, ...props }) => (
              <h3 className="text-lg font-bold mb-2" {...props} />
            ),
            p: ({ node, ...props }) => <p className="mb-2" {...props} />,
            ul: ({ node, ...props }) => (
              <ul className="list-disc list-inside mb-2" {...props} />
            ),
            ol: ({ node, ...props }) => (
              <ol className="list-decimal list-inside mb-2" {...props} />
            ),
            li: ({ node, ...props }) => <li className="mb-1" {...props} />,
            strong: ({ node, ...props }) => (
              <strong className="font-bold" {...props} />
            ),
          }}
        >
          {`${message.role === "user" ? "You:  " : "AI:  "}  ${translateMessage(message.content)}`}
        </ReactMarkdown>
      </div>
    );
  };

  return (
    // <div className="flex flex-col h-screen bg-red-100 pt-24">
    //   <div className="flex-1 overflow-y-auto bg-blue-100 mx-8 pt-10 space-y-4 h-[100px]">
    //     {messages.map((message, index) => (
    //       <div key={index} className={`flex justify-center`}>
    //         <div className="max-w-xs lg:max-w-md">{renderMessage(message)}</div>
    //       </div>
    //     ))}
    //     <div ref={messagesEndRef} />
    //   </div>
    //   <div className="p-4 bg-white shadow-md">
    //     <div className="flex items-end space-x-2">
    //       <textarea
    //         ref={textareaRef}
    //         value={input}
    //         onChange={handleInputChange}
    //         onKeyPress={handleKeyPress}
    //         className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden"
    //         placeholder="Type your message..."
    //         rows={1}
    //       />
    //       <button
    //         onClick={handleSendMessage}
    //         className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-300"
    //       >
    //         <PaperAirplaneIcon className="h-6 w-6" />
    //       </button>
    //       <button
    //         onClick={handleToggleRecording}
    //         className={`p-2 rounded-full transition-colors duration-300 ${
    //           isRecording
    //             ? "bg-red-500 hover:bg-red-600"
    //             : "bg-gray-200 hover:bg-gray-300"
    //         }`}
    //       >
    //         {isRecording ? (
    //           <StopIcon className="h-6 w-6 text-white" />
    //         ) : (
    //           <MicrophoneIcon className="h-6 w-6 text-gray-600" />
    //         )}
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <div className="flex flex-col h-screen bg-gray-100 pt-20">
      {/* "New Project" Heading */}
      <h1 className="text-3xl font-semibold   px-4">
        <span className="text-orange-500">{t("new")}</span>{" "}
        <span className="text-black">{t("project")}</span>
      </h1>

      {/* Gray Rounded Container */}
      <div className="flex-1 bg-white rounded-lg m-4 px-6 pt-2 pb-6 shadow-lg flex flex-col justify-between">
        {/* Centered "Probidder" Heading */}
        <div className="text-center mb-1">
          <h2 className="text-xl font-semibold text-blue-950">Probidder</h2>
        </div>

        {/* Chat Section */}
        <div className="flex-1 max-h-[430px] md:max-h-[900px] overflow-y-auto space-y-4 p-2 h-[200px] ">
          {messages.map((message, index) => {
            return (
              <div
                key={index}
                className={`flex justify-${
                  message.role === "user" ? "end" : "start"
                }`} // Align user messages to the right and assistant messages to the left
              >
                <div className="max-w-xs lg:max-w-md">
                  {renderMessage(message)}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
          {isLoading ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin opacity-75">
                <Loader className="w-8 h-8 text-blue-500" />
              </div>
            </div>
          ) : null}
        </div>

        {/* Input Section at the Bottom */}
        <div className="bg-white mt-2 p-2 rounded-3xl shadow-md border-gray-500 border-2">
          <div className="flex items-end space-x-2">
            {/* <button
              onClick={handleSendMessage}
              className="p-2  text-gray-500 rounded-fulltransition-colors duration-300"
            >
              <Paperclip className="h-4 w-4" />
            </button> */}
            <textarea
              ref={textareaRef}
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              className="flex-1 p-1 pl-3 focus:outline-none resize-none overflow-hidden"
              placeholder={`${t("typeYourMessage")}`}
              rows={1}
            />
            <button
              onClick={handleToggleRecording}
              className={`p-2 rounded-full transition-colors duration-300 ${
                isRecording
                  ? "bg-red-500 hover:bg-red-600"
                  : "bg-gray-50 hover:bg-gray-300"
              }`}
            >
              {isRecording ? (
                <StopIcon className="h-4 w-4 text-white" />
              ) : (
                <MicrophoneIcon className="h-4 w-4 text-gray-500" />
              )}
            </button>
            <button
              onClick={handleSendMessage}
              className="p-2 bg-gray-500 text-white rounded-full hover:bg-gray-600 transition-colors duration-300"
            >
              <ArrowUp className="h-4 w-4 font-bold " />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatUI;
