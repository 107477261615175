import { Button } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, removeToken } from "../../utils/auth";
import { ChangePasswordModal } from "./ChangePasswordModal";

const MyAccount = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignOut = () => {
    removeToken();
    navigate("/login");
  };

  return (
    <div
      className={`container mx-auto px-4 py-8 bg-gray-50 min-h-screen pt-[5rem] md:pt-30 `}
    >
      <div className="mt-8 px-4 pt-8 pb-6 bg-gray-100 flex row-span-1 items-center align-middle ">
        <h1 className="text-4xl font-bold">
          <span className="text-black">{t("my")}</span>{" "}
          <span className="text-orange-500">{t("account")}</span>
        </h1>
      </div>

      <div className="flex flex-col md:flex-row space-x-4 bg-white mt-8 py-6 ">
        {/* Commented out the user image */}
        {/*
  <img
    src="https://via.placeholder.com/100"
    alt="user avatar"
    className="rounded-full w-18 h-18"
  />
  */}
        <div className="mx-8">
          {/* Username */}
          <p className="text-sm text-gray-400 tracking-wide">userdoe</p>

          {/* Full Name with Enhanced Typography */}
          <p className="text-2xl font-bold text-gray-800 tracking-tight leading-tight">
            John Doe
          </p>

          {/* Email with Enhanced Hover Effect */}
          <a
            href="mailto:johndoe@somewhere.com"
            className="text-sm text-blue-500 hover:text-blue-700 transition duration-200 hover:underline"
          >
            johndoe@somewhere.com
          </a>
        </div>
      </div>

      <div className="absolute bottom-7 md:left-0 md:right-0 md:mx-auto flex flex-col space-y-4 mt-6 w-full max-w-md md:max-w-xl lg:max-w-2xl mx-auto py-6 px-4 md:px-10">

        {/* <Button className="rounded-lg py-2 hover:bg-gray-100 transition">
          {t("updateProfile")}
        </Button> */}
        <Button
          onClick={() => setIsModalOpen(true)} // Open modal on button click
          className=" rounded-lg py-2 hover:bg-gray-100 transition"
        >
          {t("changePassword")}
        </Button>
        <Button
          onClick={handleSignOut}
          colorScheme="orange"
          className=" rounded-lg py-2 hover:bg-gray-100 transition"
        >
          {t("signOut")}
        </Button>
      </div>

      {/* Change Password Modal */}
      <ChangePasswordModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default MyAccount;
