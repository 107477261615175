import { useState } from "react";
import { createClient } from "@deepgram/sdk";
import useShowToast from "../utils/useShowToast";
import { LiveTranscriptionEvents } from "@deepgram/sdk";
const deepgram = createClient(`2995fe96089fc46e1436e5c830e5e1ea1ad81d30`);

export default function useTranspileStream() {
  const [finalTranscript, setFinalTranscript] = useState("");
  const [interimTranscript, setInterimTranscript] = useState("");
  const [recognition, setRecognition] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [deepgramSocket, setDeepgramSocket] = useState(null);
  const showToast = useShowToast();

  const start = (stream) => {
    try {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;

      // If deepgram socket exists and is open, reuse it instead of creating new one
      if (deepgramSocket && deepgramSocket.readyState === deepgramSocket.OPEN) {
        console.log("Reusing existing Deepgram socket", deepgramSocket.readyState);
        return;
      }

      console.log(
        "SpeechRecognition available:",
        !!SpeechRecognition,
        SpeechRecognition
      );

      // Check if running on Android Chrome
      // Check browser and device type
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent
      );
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const isMobile = /Mobi|Android/i.test(navigator.userAgent);

      if (isMobile) {
        initiateDeepGram(stream);
        return;
      }
      let recog = new SpeechRecognition();
      setRecognition(recog);
      recog.continuous = true;
      recog.interimResults = true;
      recog.lang = "en-US";
      recog.continuous = true;
      recog.maxAlternatives = 1;
      recog.onresult = function (event) {
        if (!stream) return;
        let interimTranscript = "";
        let finalTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        console.log("Processed transcripts:", {
          finalTranscript,
          interimTranscript,
        });
        setFinalTranscript((prevFinal) => prevFinal + finalTranscript);
        setInterimTranscript(interimTranscript);
      };
      recog.onerror = function (event) {
        console.error("Speech recognition error:", {
          error: event.error,
          message: event.message,
          details: event,
        });
        // Fallback to Deepgram on error
        if (event.error === "no-speech" || event.error === "audio-capture") {
          console.log("Falling back to Deepgram due to error");
          recog.stop();
          initiateDeepGram(stream);
        }
      };
      recog.onend = function () {
        console.log("Speech recog service disconnected");
      };
      recog.start();
    } catch (error) {
      showToast(error);
      console.log(error);
    }
  };

  const stop = () => {
    console.log(
      "Stop called with recognition state:",
      recognition,
      deepgramSocket
    );

    if (recognition) {
      recognition.stop();
      console.log("stop the stream");
    }

    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }

    if (deepgramSocket) {
      console.log("Closing deepgram socket", deepgramSocket);
      deepgramSocket.requestClose();
    } else {
      console.log("No deepgram socket to close");
    }

    // Clear all transcriptions
    setFinalTranscript("");
    setInterimTranscript("");
    setMediaRecorder(null);
  };

  const initiateDeepGram = (stream) => {
    try {
      if (!stream.active) return;

      // If socket exists and is open, reuse it
      if (deepgramSocket && deepgramSocket.readyState === deepgramSocket.OPEN) {
        console.log("Reusing existing Deepgram socket");
        return;
      }

      let socket = deepgram.listen.live({
        model: "nova",
        smart_format: true,
      });
      setDeepgramSocket(socket);

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      socket.on(LiveTranscriptionEvents.Open, async () => {
        recorder.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0 && socket.readyState === socket.OPEN) {
            socket.send(event.data);
          }
        });

        recorder.start(250);

        socket.on(LiveTranscriptionEvents.Transcript, (data) => {
          const transcript = data.channel.alternatives[0].transcript;
          if (data.is_final) {
            setFinalTranscript((prevFinal) => {
              if (prevFinal.endsWith(transcript)) {
                return prevFinal;
              }
              return prevFinal ? prevFinal + " " + transcript : transcript;
            });
            setInterimTranscript("");
          } else {
            console.log("Interim transcript received:", transcript);
            setInterimTranscript(transcript);
          }
        });

        socket.on(LiveTranscriptionEvents.Error, (e) =>
          console.error("Deepgram error:", e)
        );
        socket.on(LiveTranscriptionEvents.Warning, (e) =>
          console.warn("Deepgram warning:", e)
        );
        socket.on(LiveTranscriptionEvents.Metadata, (e) =>
          console.log("Deepgram metadata:", e)
        );
        socket.on(LiveTranscriptionEvents.Close, (e) => {
          console.log("Deepgram connection closed:", e);
          socket.requestClose();
          setDeepgramSocket(null);
          if (recorder.state !== "inactive") {
            recorder.stop();
          }
        });
      });
    } catch (error) {
      console.error("Deepgram setup failed:", error);
      showToast(error);
    }
  };

  return {
    startTranscription: start,
    stopTranscription: stop,
    interimTranscript,
    finalTranscript,
    setFinalTranscript,
  };
}