// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ChatUI from "./components/RealtimeTranscription";

import LoginScreen from "./components/auth/loginScreen";
import RegisterScreen from "./components/auth/registerScreen";
import PasswordResetRequestScreen from "./components/auth/passwordResetRequestScreen";
import PasswordResetConfirmScreen from "./components/auth/passwordResetConfirmScreen";
import ChangePasswordScreen from "./components/auth/changePasswordScreen";
import DeleteAccountScreen from "./components/auth/deleteAccountScreen";

import DashboardScreen from "./components/dashboard/dashboardScreen";
import CreateProjectScreen from "./components/dashboard/createProjectScreen";

// Assume we have this function in utils/auth.js
import { isAuthenticated } from "./utils/auth";
import Header from "./components/header/Header";
import ProjectDetails from "./components/Projects/ProjectDetails/ProjectDetails";
import { LanguageProvider } from "./utils/LanguageContext";
import ChatPage from "./components/chat/ChatPage";
import MyAccount from "./components/account/MyAccount";
import { ChakraProvider } from "@chakra-ui/react";
import NewChat from "./components/chat/NewChat";
import ResetPassword from "./components/account/ResetPassword";

// Protected route component
const ProtectedRoute = ({ element: Element, ...rest }) => {
  // Check if user is authenticated, and render the Header + the protected route
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      {/* Header will only render for authenticated users */}
      <Header />
      <Element {...rest} />
    </>
  );
};

function App() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <Router>
          <div className={`App`}>
            <Routes>
              {/* Public routes */}
              <Route path="/login" element={<LoginScreen />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/register" element={<RegisterScreen />} />
              <Route
                path="/password-reset"
                element={<PasswordResetRequestScreen />}
              />
              <Route
                path="/reset-password/:uidb64/:token"
                element={<PasswordResetConfirmScreen />}
              />

              {/* Protected routes */}
              <Route
                path="/chat/:id"
                element={<ProtectedRoute element={NewChat} />}
                // element={<ProtectedRoute element={ChatUI} />}
              />
              <Route
                path="/change-password"
                element={<ProtectedRoute element={ChangePasswordScreen} />}
              />
              <Route
                path="/delete-account"
                element={<ProtectedRoute element={DeleteAccountScreen} />}
              />

              <Route
                path="/myAccount"
                element={<ProtectedRoute element={MyAccount} />}
              />

              <Route
                path="/dashboard"
                element={<ProtectedRoute element={DashboardScreen} />}
              />

              <Route
                path="/chatss"
                element={<ProtectedRoute element={ChatPage} />}
              />
              <Route
                path="/project/:id"
                element={<ProtectedRoute element={ProjectDetails} />}
              />
              <Route
                path="/create-project"
                element={<ProtectedRoute element={CreateProjectScreen} />}
              />

              {/* Default route */}
              <Route path="/" element={<Navigate replace to="/login" />} />

              {/* Catch-all route for undefined paths */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </div>
        </Router>
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default App;
