import React, { useEffect, useState } from 'react';
import { Edit2, X, MapPin, Grid2x2, Bed, Bath, Building, Clock } from 'lucide-react';
import StyledButton from '../ui/styledButton';
import StyledInput from '../ui/styledInput';
import { Card, CardContent, CardFooter } from '../ui/card';
import { useTranslation } from 'react-i18next';

const ProjectConfirmationDialog = ({ isOpen, onClose, onConfirm, projectData, isLoading }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(null);
  const placeholderUrl = "https://i.gifer.com/KDVh.gif";
  const { t } = useTranslation();

  useEffect(() => {
    if (projectData) {
      setEditedData(projectData);
    }
  }, [projectData]);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen || !editedData) return null;

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setEditedData(projectData);
    setIsEditing(false);
  };

  const handleSaveEdit = () => {
    setIsEditing(false);
  };

  const handleInputChange = (field, value) => {
    setEditedData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleConfirmClick = () => {
    onConfirm(editedData);
  };

  const renderFooterButtons = () => {
    if (isEditing) {
      return (
        <>
          <StyledButton
            variant="outline"
            onClick={handleCancelEdit}
            disabled={isLoading}
          >
            {t("cancel")}
          </StyledButton>
          <StyledButton
            onClick={handleSaveEdit}
            disabled={isLoading}
          >
            {t("save")}
          </StyledButton>
        </>
      );
    }

    return (
      <>
        <StyledButton
          variant="outline"
          onClick={onClose}
          disabled={isLoading}
        >
          {t("cancel")}
        </StyledButton>
        <StyledButton
          onClick={handleConfirmClick}
          isLoading={isLoading}
        >
          {t("confirmCreateProject")}
        </StyledButton>
      </>
    );
  };

  // Editable detail card component
  const DetailCard = ({ label, field, value, type = "text", icon: IconComponent }) => (
    <div className="space-y-2">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      {isEditing ? (
        <StyledInput
          icon={IconComponent}
          type={type}
          value={value || ''}
          onChange={(e) => handleInputChange(field, e.target.value)}
          placeholder={label}
        />
      ) : (
        <div className="flex items-center space-x-2 p-2 bg-gray-50 rounded-md">
          <IconComponent className="h-4 w-4 text-gray-500" />
          <span className="text-sm text-gray-900">{value || 'N/A'}</span>
        </div>
      )}
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="flex min-h-full items-center justify-center p-4">
        <Card 
          onClick={handleContentClick}
          className="w-full max-w-2xl bg-white shadow-xl relative"
        >
          {/* Header */}
          <div className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center bg-white rounded-t-lg z-10">
            <h3 className="text-lg font-semibold text-gray-900">
              {t("confirmProjectDetails")}
            </h3>
            <div className="flex items-center gap-2">
              {!isEditing && (
                <button
                  onClick={handleEdit}
                  className="flex items-center gap-2 px-3 py-2 bg-orange-100 text-orange-600 rounded-md hover:bg-orange-200 transition-colors duration-200"
                >
                  <Edit2 className="w-4 h-4" />
                  <span className="text-sm font-medium">Edit</span>
                </button>
              )}
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
              >
                <X className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>

          {/* Image */}
          <div className="relative w-full h-48 sm:h-64 bg-gray-100 rounded-t-lg overflow-hidden mt-14">
            {editedData?.property_image ? (
              <img
                src={editedData.property_image}
                alt="Property"
                className="w-full h-full object-cover"
                onError={(e) => {
                  e.target.src = placeholderUrl;
                }}
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center">
                <img
                  src={placeholderUrl}
                  alt="Placeholder"
                  className="w-16 h-16 object-cover rounded-lg"
                  loading="lazy"
                />
              </div>
            )}
          </div>

          {/* Content */}
          <CardContent className="p-6">
            <div className="space-y-6">
              <DetailCard 
                label={t("address")}
                field="address"
                value={editedData.address}
                icon={MapPin}
              />
              
              <div className="grid grid-cols-2 gap-4">
                <DetailCard 
                  label={t("squareFootage")}
                  field="square_footage"
                  value={editedData.square_footage}
                  type="number"
                  icon={Grid2x2}
                />
                <DetailCard 
                  label={t("bedrooms")}
                  field="total_bedrooms"
                  value={editedData.total_bedrooms}
                  type="number"
                  icon={Bed}
                />
                <DetailCard 
                  label={t("bathrooms")}
                  field="total_bathrooms"
                  value={editedData.total_bathrooms}
                  type="number"
                  icon={Bath}
                />
                <DetailCard 
                  label={t("stories")}
                  field="stories"
                  value={editedData.stories}
                  type="number"
                  icon={Building}
                />
                <DetailCard 
                  label={t("yearBuilt")}
                  field="year_built"
                  value={editedData.year_built}
                  type="number"
                  icon={Clock}
                />
              </div>
            </div>
          </CardContent>

          {/* Footer */}
          <CardFooter className="bg-gray-50 p-4 border-t border-gray-200">
            <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:gap-3 w-full">
              {renderFooterButtons()}
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default ProjectConfirmationDialog;