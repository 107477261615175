import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  IconButton,
  Flex
} from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

export function ConfirmationModal({
  isOpen,
  onClose,
  title,
  description,
  actionLabel = 'Confirm',
  cancelLabel = 'Cancel',
  onConfirm,
  variant = 'warning'
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={4}>
        <Flex justify="space-between" align="center" p={6} pb={0}>
          <ModalHeader p={0} fontSize="lg"  fontWeight="bold">{title}</ModalHeader>
          <IconButton
            icon={<CloseIcon w={3} h={3} />}
            onClick={onClose}
            variant="ghost"
            size="sm"
            borderRadius="full"
            aria-label="Close modal"
          />
        </Flex>

        <ModalBody pt={2}>
          <Text fontSize="sm" color="gray.500">
            {description}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            mr={3}
            onClick={onClose}
            size="sm"
            _hover={{ bg: 'gray.100' }}
          >
            {cancelLabel}
          </Button>
          <Button
            colorScheme={variant === 'warning' ? 'red' : 'orange'}
            onClick={onConfirm}
            size="sm"
          >
            {actionLabel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
