import { Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const JumpToSectionLG = ({ chatDetails, selectedSection, setSelectedSection }) => {
  const { t } = useTranslation();
  const project_setup = t("projectSetup");
  const interiot_sections = t("interiorSections");
  return (
    <div className="relative hidden md:block">
      {/* Label and Desktop View Select */}
      <div className="flex items-center space-x-2">
        <Select
          size={"sm"}
          id="section-select"
          className="py-[1.5px] px-2 rounded border-4 border-gray-300 hidden md:block"
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
          color={"orange"}
        >
          <option value="setup">{`${project_setup}`}</option>
          {Object.keys(chatDetails || {}).map((section) => {
            const sectionData = chatDetails[section];
            const hasContent = Object.values(sectionData)?.some(
              item => item && (item?.content !== "NA" || item?.url)
            );
            if (hasContent) {
              return (
                <option key={section} value={section}>
                  {t(section)}
                </option>
              );
            }
            return null;
          })}
        </Select>
      </div>
    </div>
  );
};

export default JumpToSectionLG;
