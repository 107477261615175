import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Lock, Key } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';
import AuthFormWrapper from './authFormWrapper';
import StyledButton from '../ui/styledButton';
import StyledInput from '../ui/styledInput';

// Change Password Screen
const ChangePasswordScreen = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!currentPassword) newErrors.currentPassword = 'Current password is required';
    if (!newPassword) newErrors.newPassword = 'New password is required';
    if (newPassword !== confirmNewPassword) newErrors.confirmNewPassword = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setIsLoading(false);
        setSuccess(true);
      }, 2000);
    }
  };

  return (
    <AuthFormWrapper title="Change your password">
      {success ? (
        <Alert>
          <AlertDescription>Your password has been successfully changed.</AlertDescription>
        </Alert>
      ) : (
        <form className="mt-8 space-y-6" onSubmit={handleChangePassword}>
          <div>
            <StyledInput
              icon={Lock}
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current password"
              error={errors.currentPassword}
              required
            />
            <StyledInput
              icon={Lock}
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New password"
              error={errors.newPassword}
              required
            />
            <StyledInput
              icon={Lock}
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Confirm new password"
              error={errors.confirmNewPassword}
              required
            />
          </div>
          <div>
          <StyledButton type="submit" isLoading={isLoading}>
              Change password
              <Key className="ml-2 h-4 w-4" />
            </StyledButton>
          </div>
        </form>
      )}
      <div className="text-center mt-6">
        <Link to="/dashboard" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
          Back to Dashboard
        </Link>
      </div>
    </AuthFormWrapper>
  );
};


export default ChangePasswordScreen;