import { useToast } from "@chakra-ui/react";

const useShowToast = () => {
  const toast = useToast();

  const showToast = (message, status = "info", duration = 5000, position = "top-right") => {
    toast({
      title: message,
      status: status,
      duration: duration,
      isClosable: true,
      position: position,
    });
  };

  return showToast;
};

export default useShowToast;