import { MicrophoneIcon, StopIcon } from "@heroicons/react/24/solid";
import axios from "axios";
import {
  ArrowUp,
  CameraIcon,
  CircleStop,
  Disc,
  Loader,
  Mic,
  StopCircle,
  Video,
  FlipHorizontal,
  ScanFace,
} from "lucide-react";
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { Button, Textarea, useToast, Alert, AlertIcon, Box, Flex, IconButton } from "@chakra-ui/react"; // Added Alert components
import useHandleSessionExpired from "../../utils/useHandleSessionExpired";
import useShowToast from "../../utils/useShowToast";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { LanguageContext } from "../../utils/LanguageContext";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { useCookies } from "../../utils/useCookie";
import Cookies from "js-cookie";
import useTranspileStream from "../../hooks/useTranspileStream";
import { useGetChatHistoryQuery } from "../../api/apiSlice";
import ProgressBar from "../ui/progressBar";
import debounce from "lodash.debounce";


const s3Client = new S3Client({
  region: "eu-north-1",
  credentials: {
    accessKeyId: `AKIA444U67HQBZJDXJEF`,
    secretAccessKey: `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`,
  },
});

const sentenceMap = {
  "What is the address?": "whatIsTheAddress",
  "Please provide room details manually": "pleaseProvideRoomDetailsManually",
  "Confirm information provided has the correct bed and bath count?":
    "confirmInformCount",
};

function NewChat() {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useContext(LanguageContext);
  const handleSessionExpired = useHandleSessionExpired();
  const toast = useToast();
  const showToast = useShowToast();
  const { t } = useTranslation();
  const [sessionId, setSessionId] = useState(null); // State to store sessionid
  const { getCookie, setCookie, removeCookie, cookieValue } = useCookies();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [conversationResp, setConversationResp] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [micDisabled, setMicDisabled] = useState(false);
  const [showHeading, setShowHeading] = useState(true); // State to control heading visibility
  const [showContinue, setShowContinue] = useState(false); // State to control heading visibility
  const [isVideoRecording, setIsVideoRecording] = useState(false); // Track video recording status
  const [facingMode, setFacingMode] = useState("user"); // Track camera mode - "user" for front, "environment" for back
  const mediaRecorderRef = useRef(null); // Ref for MediaRecorder
  const videoRef = useRef(null); // Ref for video element
  const recordedChunks = useRef([]); // To store video data chunks
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const recordingStartedRef = useRef(false);

  const {
    startTranscription,
    stopTranscription,
    finalTranscript: transcription,
    interimTranscript,
  } = useTranspileStream();

  const { data: chatHistory, isLoading: chatHistoryLoading } =
    useGetChatHistoryQuery(id);

  // Extract the projectName from location.state
  const projectData = location.state?.projectData || null;
  const projectName = projectData?.name || null;

  const translateMessage = (content) => {
    return sentenceMap[content] ? t(sentenceMap[content]) : content;
  };

  async function requestPermissions(mediaType) {
    let stream;

    try {
      // Use the appropriate method based on mediaType
      if (mediaType.audio) {
        // Request audio permission
        stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      } else if (mediaType.video) {
        // Request video and audio permission with specified facing mode
        stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: facingMode },
          audio: true,
        });
      }

      return stream; // Return the media stream if permissions are granted
    } catch (err) {
      console.error("Error accessing media devices:", err);
      // Notify user of permission denial
      toast({
        title: "Permission Denied",
        description: `You need to allow ${
          mediaType.audio ? "audio" : "video"
        } access.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return null; // Return null if permission is denied
    }
  }

  // Function to switch camera
  const switchCamera = async () => {
    const newFacingMode = facingMode === "user" ? "environment" : "user";
    setFacingMode(newFacingMode);
  };

  useEffect(() => {
    if (recordingStartedRef.current && !isRecording && !isVideoRecording) {
      stopRecording();
    }
  }, [isRecording, recordingStartedRef.current, isVideoRecording]);

  useEffect(() => {
    if (chatHistory?.length && !messages.length) {
      const formattedHistory = [];

      chatHistory.forEach((historyItem, index) => {
        // Add AI message asking about the item
        formattedHistory.push({
          role: "assistant",
          message: {
            content: `Please tell me about the ${historyItem.item.replaceAll(
              "_",
              " "
            )} for this ${historyItem.phase}`,
            url: null,
          },
          phase: historyItem.phase,
          item: historyItem.item,
        });

        // Add user's response
        formattedHistory.push({
          role: "user",
          message: {
            content: historyItem.message.content,
            url: historyItem.message.url,
          },
          phase: historyItem.phase,
          item: historyItem.item,
        });

        // If this isn't the last item, add a transition AI message
        // if (index < chatHistory.length - 1) {
        //   formattedHistory.push({
        //     role: "assistant",
        //     message: {
        //       content: "Thank you. Let's move on to the next item.",
        //       url: null,
        //     },
        //     phase: historyItem.phase,
        //     item: historyItem.item,
        //   });
        // }
      });

      setMessages(formattedHistory);

      // Set the last conversation response
      if (formattedHistory.length > 0) {
        const lastMessage = chatHistory[chatHistory.length - 1];
        setConversationResp({
          phase: lastMessage.phase,
          item: lastMessage.item,
          message: lastMessage.message.content,
        });
      }

      setShowHeading(false);
      setShowContinue(false);
    }
  }, [chatHistory]);

  useEffect(() => {
    // Get the session ID cookie on component mount
    if (!sessionId) {
      const session_Id = Cookies.get("sessionid");
      setSessionId(session_Id);
    }
  }, [conversationResp]);

  useEffect(() => {
    if (projectName?.length && projectName !== null) {
      setShowContinue(true);
    }
  }, [projectName]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    adjustTextareaHeight();
  }, [messages, input]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (
      lastMessage &&
      translateMessage(lastMessage.content) === "All questions answered."
    ) {
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    }
  }, [messages, navigate]);

  // Update input when transcription changes
  useEffect(() => {
    if (interimTranscript) {
      setInput(interimTranscript);
    } else if (transcription) {
      setInput(transcription);
    }
  }, [interimTranscript, transcription]);

  //upload video to S3Bucket
  async function uploadVideoToS3(videoBlob) {
    const videoFile = new File([videoBlob], "recorded-video.webm", {
      type: "video/webm",
    });

    const uploadParams = {
      Bucket: "probidder-bucket", // S3 bucket name
      Key: `videos/${Date.now()}-${videoFile.name}`, // Unique file key
      Body: videoFile,
      ContentType: videoFile.type,
      ACL: "public-read", // Optional: if you want the video to be publicly accessible
    };

    try {
      const command = new PutObjectCommand(uploadParams);
      const response = await s3Client.send(command);
      const videoURL = `https://${uploadParams.Bucket}.s3.amazonaws.com/${uploadParams.Key}`;
      return videoURL;
    } catch (err) {
      console.error("Error uploading video to S3:", err);
      throw err;
    }
  }

  const startVideoRecording = async () => {
    setIsVideoRecording(true);
    const stream = await requestPermissions({ video: true, audio: true });
    if (!stream) return; // If permissions are not granted, return
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
    try {
      recordedChunks.current = [];
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: facingMode },
        audio: true,
      });

      // Assign the stream to the video element
      videoRef.current.srcObject = stream;

      // Wait for the video metadata to load before attempting to play
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      };

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "video/webm",
      });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunks.current.push(event.data);
        }
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;

      startTranscription(stream);
      recordingStartedRef.current = true;
    } catch (error) {
      console.error("Error starting video recording:", error);
      showToast("Video recording failed", "error");
    }
  };

  // Stop recording both video and audio
  const stopVideoRecording = () => {
    setIsVideoRecording(false);
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.onstop = () => {
        const recordedBlob = new Blob(recordedChunks.current, {
          type: "video/webm",
        });
      };

      mediaRecorderRef.current.stop();
      setTimeout(() => {
        stopRecording();
        recordingStartedRef.current = false;
      }, 1300);
      if (videoRef.current) {
        videoRef.current.srcObject = null; // Clear the video source
      }
    }
  };

  const startRecording = async () => {
    setIsRecording(true);
    setMicDisabled(true);
    const stream = await requestPermissions({ audio: true });
    if (!stream) return;
    setMicDisabled(false);
    startTranscription(stream); // Start audio transcription using the new hook
    recordingStartedRef.current = true; // Set flag when recording successfully starts
  };

  const stopRecording = () => {
    setIsRecording(false);
    setTimeout(() => {
      stopTranscription();
      recordingStartedRef.current = false;
    }, 1500);
  };

  const handleSendMessage = async (skip) => {
    if (skip?.skipQuestionaire) {
      setInput("NA");
    }

    // Stop recording and clear input
    await stopRecording();
    await stopVideoRecording();
    stopTranscription();
    setInput("");

    setIsLoading(true);
    await new Promise((resolve) => {
      setTimeout(resolve, 500); // Small delay to ensure recordedChunks is populated
    });
    let videoURL = null;

    // Check if a video was recorded and needs to be uploaded
    if (recordedChunks.current.length > 0 && !skip?.skipQuestionaire) {
      try {
        const recordedBlob = new Blob(recordedChunks.current, {
          type: "video/webm",
        });
        videoURL = await uploadVideoToS3(recordedBlob); // Upload to S3
      } catch (error) {
        console.error("Error uploading video:", error);
        setIsLoading(false);
        return;
      }
    }

    const userMessage = {
      role: "user",
      message: {
        content: input,
        url: videoURL,
      },
      phase: conversationResp?.phase,
      item: conversationResp?.item,
    };

    // Remove the duplicate setMessages calls and just keep one
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setShowHeading(false);
    setShowContinue(false);

    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.value = "";
    }

    // Prepare payload for API call
    let payload = showContinue
      ? {
          message: "Hi",
          id: id,
        }
      : conversationResp
      ? {
          ...conversationResp,
          message: {
            content: skip?.skipQuestionaire ? "NA" : input,
            url: videoURL,
          },
          id: id,
        }
      : skip?.skipQuestionaire
      ? { message: "NA" }
      : { message: "Hi", id: id };
    if (
      conversationResp?.message ===
      "Please speak out again, the address, square footage, total bedrooms, total bathrooms, year built, stories"
    ) {
      payload = { ...payload, details: true };
    }

    try {
      const url = `${process.env.REACT_APP_API_URL}/api/chat-questionaire/`;
      const response = await axios.post(url, payload, {
        withCredentials: true,
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      console.log(111, response.headers, response.data);

      const assistantMessage = {
        role: "assistant",
        message: {
          content: response.data.message,
          url: response.data.url,
        },
        item: response.data.item,
        phase: response.data.phase,
      };

      setMessages((prevMessages) => [...prevMessages, assistantMessage]);
      setConversationResp(response.data);
      setIsLoading(false);

      recordedChunks.current = [];
    } catch (error) {
      setIsLoading(false);
      setInput("");
      console.error("Error sending message:", error);
      if (error?.response?.status === 401) {
        handleSessionExpired();
      } else {
        showToast("Something went wrong!", "warning");
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  const renderMessage = (message) => {
    const messageClass =
      message.role === "user"
        ? "border-2 text-gray-600 font-semibold"
        : "border-2 text-gray-600 shadow";
    if (message?.message?.content?.length) {
      return (
        <>
          {message?.item && (
            <div className="my-2">
              <h2 className="capitalize">
                {message.item.replaceAll("_", " ")}
              </h2>
            </div>
          )}
          <div className={`sm:p-3 p-2 rounded-lg ${messageClass}`}>
            {message?.message?.url && (
              <video
                src={message?.message?.url}
                controls
                className="w-full sm:max-h-400px md:max-h-500px max-h-200px rounded"
              />
            )}
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1 className="text-2xl font-bold mb-2" {...props} />
                ),
                h2: ({ node, ...props }) => (
                  <h2 className="text-xl font-bold mb-2" {...props} />
                ),
                h3: ({ node, ...props }) => (
                  <h3 className="text-lg font-bold mb-2" {...props} />
                ),
                p: ({ node, ...props }) => <p className="mb-2" {...props} />,
                ul: ({ node, ...props }) => (
                  <ul className="list-disc list-inside mb-2" {...props} />
                ),
                ol: ({ node, ...props }) => (
                  <ol className="list-decimal list-inside mb-2" {...props} />
                ),
                li: ({ node, ...props }) => <li className="mb-1" {...props} />,
                strong: ({ node, ...props }) => (
                  <strong className="font-bold" {...props} />
                ),
              }}
            >
              {`${
                message.role === "user"
                  ? message?.message?.content?.length
                    ? "You:  "
                    : "You:  "
                  : "AI:  "
              }  ${translateMessage(message?.message?.content)}`}
            </ReactMarkdown>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    const requestPermissions = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        // Now you can use the stream for recording or audio processing
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };

    requestPermissions();
  }, []);

  const handleMouseDown = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleMouseUp = () => {
    stopRecording();
    handleSendMessage({ skipQuestionaire: false });
  };

  const handleTouchStart = async (e) => {
    setMicDisabled(true);
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const handleTouchEnd = (e) => {
    setIsRecording(false);
    e.preventDefault();
    stopRecording();
    setTimeout(() => {
      setMicDisabled(false);
      handleSendMessage({ skipQuestionaire: false });
    }, 2000);
  };

  const handleRecordVideo = () => {
    if (isVideoRecording) {
      handleStopVideoRecording();
    } else {
      startVideoRecording();
    }
  };

  const handleStopVideoRecording = () => {
    setIsVideoRecording(false);
    stopVideoRecording();
    handleSendMessage({ skipQuestionaire: false });
  };

  const handleFinish = async () => {
    try {
      const updatedPayload = {
        completed: true,
        pc_completion: 100,
      };

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/projects/${id}/`,
        updatedPayload,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      showToast("Project updated successfully", "success");
      navigate("/dashboard");
    } catch (err) {
      showToast("Something went wrong", "warning");
    }
  };


  const buttonLockRef = useRef(false); // Shared lock state

// Debounced Start Recording
const debouncedStartRecording = useCallback(
  debounce(async () => {
    if (buttonLockRef.current) return;
    buttonLockRef.current = true;
    try {
      await startRecording(); // Your existing recording logic
    } finally {
      setTimeout(() => (buttonLockRef.current = false), 300); // Unlock after delay
    }
  }, 300), // Adjust debounce time as needed
  []
);

// Debounced Stop Recording
const debouncedStopRecording = useCallback(
  debounce(() => {
    if (buttonLockRef.current) return;
    buttonLockRef.current = true;
    try {
      stopRecording(); // Your existing stop logic
    } finally {
      setTimeout(() => (buttonLockRef.current = false), 300); // Unlock after delay
    }
  }, 300),
  []
);

// Debounced Start Video Recording
const debouncedStartVideoRecording = useCallback(
  debounce(async () => {
    if (buttonLockRef.current) return;
    buttonLockRef.current = true;
    try {
      await startVideoRecording(); // Your existing video start logic
    } finally {
      setTimeout(() => (buttonLockRef.current = false), 300); // Unlock after delay
    }
  }, 300),
  []
);

// Debounced Stop Video Recording
const debouncedStopVideoRecording = useCallback(
  debounce(() => {
    if (buttonLockRef.current) return;
    buttonLockRef.current = true;
    try {
      stopVideoRecording(); // Your existing video stop logic
    } finally {
      setTimeout(() => (buttonLockRef.current = false), 300); // Unlock after delay
    }
  }, 300),
  []
);

    return (
    <div className="flex flex-col h-screen pt-[80px]">
      {!showContinue && projectName && (
        <div className="fixed top-[80px] left-0 right-0 bg-white z-30 shadow-sm">
          <div className="w-full px-2 py-2 sm:px-4 sm:py-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <span className="text-gray-600 text-base sm:text-lg">
                  {t("project")}:
                </span>
                <span className="text-orange-500 text-base sm:text-lg font-semibold truncate max-w-[150px] sm:max-w-[200px]">
                  {projectName}
                </span>
              </div>
              {conversationResp?.completion && (
                <div className="w-32 flex flex-col items-end">
                  <ProgressBar
                    percentage={Number(conversationResp.completion)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex-1 w-full mx-auto px-2 sm:px-4 overflow-hidden">
        <div className="bg-white h-full flex flex-col">
          {(showContinue || showHeading) && (
            <div className="text-center py-2 sm:py-4 border-b bg-white">
              <h2 className="text-lg sm:text-xl font-semibold text-blue-950">
                Probidder
              </h2>
              <h2 className="text-base sm:text-lg mt-1 sm:mt-2 text-gray-600">
                {showContinue ? t("continueChat") : t("recordHiToInitiate")}
              </h2>
            </div>
          )}

          {isRecording && (
            <div className="px-2 sm:px-4 py-1 sm:py-2">
              <Alert status="info">
                <AlertIcon />
                <span className="text-sm sm:text-base">
                  Recording audio... Speak now
                </span>
              </Alert>
            </div>
          )}

          <div
            className="flex-1 overflow-y-auto px-2 sm:px-4 py-2 sm:py-4 space-y-3"
            style={{
              scrollbarWidth: "thin",
              scrollbarColor: "#CBD5E0 #EDF2F7",
            }}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex items-start ${
                  message.role === "user" ? "justify-end" : "justify-start"
                } gap-2`}
              >
                {message.role === "user" &&
                  message.message.content.length > 0 && (
                    <div className="flex items-start">
                      {message.message.url ? (
                        <Video className="h-3 w-3 sm:h-4 sm:w-4 text-gray-500 mt-2" />
                      ) : (
                        <Mic className="h-3 w-3 sm:h-4 sm:w-4 text-gray-500 mt-2" />
                      )}
                    </div>
                  )}
                <div
                  className={`max-w-[85%] sm:max-w-[75%] rounded-lg px-3 py-2 sm:px-4 sm:py-3 shadow-sm ${
                    message.role === "user"
                      ? "bg-blue-50 text-gray-800"
                      : "bg-gray-50 text-gray-700"
                  }`}
                >
                  {message?.item && (
                    <div className="text-xs sm:text-sm font-medium text-gray-500 mb-1 capitalize">
                      {message.item.replaceAll("_", " ")}
                    </div>
                  )}
                  {message?.message?.url && (
                    <div className="mb-2">
                      <video
                        src={message.message.url}
                        controls
                        className="w-full rounded-lg max-h-[200px] sm:max-h-[300px] object-contain"
                      />
                    </div>
                  )}
                  <div className="break-words text-sm sm:text-base">
                    <span className="font-medium">
                      {message.role === "user" ? "You: " : "AI: "}
                    </span>
                    {translateMessage(message.message.content)}
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
            {isLoading && (
              <div className="flex justify-center py-2">
                <Loader className="w-6 h-6 sm:w-8 sm:h-8 text-blue-500 animate-spin" />
              </div>
            )}
          </div>

          {isVideoRecording && (
            <div className="fixed bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
              <div className="w-full max-w-xl">
                <video
                  ref={videoRef}
                  playsInline
                  muted
                  className="w-full rounded-lg shadow-lg"
                />
              </div>
            </div>
          )}

          <div className="p-2 sm:p-4 border-t bg-gray-50">
            <div className="flex justify-center space-x-4 sm:space-x-8 mb-3">
              <div className="flex flex-col items-center">
              <button
  onMouseDown={!micDisabled ? debouncedStartRecording : undefined}
  onMouseUp={!micDisabled ? debouncedStopRecording : undefined}
  onTouchStart={!micDisabled ? debouncedStartRecording : undefined}
  onTouchEnd={debouncedStopRecording}
  disabled={micDisabled}
  className={`p-3 sm:p-4 rounded-full transition-all duration-300 shadow-lg ${
    isRecording
      ? "bg-red-500 hover:bg-red-600 scale-110"
      : "bg-blue-100 hover:bg-blue-200"
  }`}
>
  <Mic
    className={`h-5 w-5 sm:h-6 sm:w-6 ${
      isRecording ? "text-white" : "text-blue-600"
    }`}
  />
</button>
                <p className="text-xs mt-1 text-gray-600 text-center">
                  {t("holdAudioToRecord")}
                </p>
              </div>

              {conversationResp && (
                <div className="flex flex-col items-center">
                  <div className="flex space-x-2">
                    <button
                      onTouchStart={handleRecordVideo}
                      onTouchEnd={handleStopVideoRecording}
                      onMouseDown={handleRecordVideo}
                      onMouseUp={handleStopVideoRecording}
                      className={`p-3 sm:p-4 rounded-full transition-all duration-300 shadow-lg ${
                        isVideoRecording
                          ? "bg-red-500 hover:bg-red-600 scale-110"
                          : "bg-red-100 hover:bg-red-200"
                      }`}
                    >
                      {isVideoRecording ? (
                        <CircleStop className="h-5 w-5 sm:h-6 sm:w-6 text-white" />
                      ) : (
                        <Video className="h-5 w-5 sm:h-6 sm:w-6 text-red-600" />
                      )}
                    </button>
                    <button
                      onClick={switchCamera}
                      className="p-3 sm:p-4 rounded-full bg-gray-100 hover:bg-gray-200 transition-all duration-300 shadow-lg"
                    >
                      {facingMode === "user" ? (
                        <ScanFace className="h-5 w-5 sm:h-6 sm:w-6 text-gray-600" />
                      ) : (
                        <FlipHorizontal className="h-5 w-5 sm:h-6 sm:w-6 text-gray-600" />
                      )}
                    </button>
                  </div>
                  <p className="text-xs mt-1 text-gray-600 text-center">
                    {t("holdVideoToRecord")}
                  </p>
                </div>
              )}
            </div>
            
            <Flex
              alignItems="center"
              mb={3}
              border="2px solid"
              borderColor="gray.300"
              borderRadius="full"
              bg="white"
              shadow="sm"
              overflow="hidden"
            >
              <Textarea
                ref={textareaRef}
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder={t("recordToReply")}
                resize="none"
                rows={1}
                flex="1"
                px={4}
                py={4}
                fontSize={{ base: "sm", sm: "md" }}
                variant="unstyled"
                _focus={{ outline: "none" }}
                maxH={isVideoRecording || isRecording ? "3rem" : "6rem"}
              />
              <IconButton
                isDisabled={isLoading}
                onClick={() => handleSendMessage({ skipQuestionaire: false })}
                icon={<ArrowUp size="1em" />}
                aria-label="Send message"
                borderRadius="full"
                bg={input.length ? "blue.500" : "gray.300"}
                color="white"
                mx={1}
                _hover={input.length ? { bg: "blue.600" } : {}}
                _disabled={{ opacity: 0.5, cursor: "not-allowed" }}
              />
            </Flex>

            <div className="flex justify-between items-center px-1 py-1">
              <Button
                colorScheme="orange"
                variant="outline"
                size={{ base: "sm", sm: "md" }}
                leftIcon={<ArrowLeft className="h-3.5 w-3.5 sm:h-4 sm:w-4" />}
                borderRadius="full"
                boxShadow="sm"
                px={{ base: "3", sm: "6" }}
                fontSize={{ base: "xs", sm: "sm" }}
                fontWeight="medium"
                h={{ base: "32px", sm: "40px" }}
                minW={{ base: "auto", sm: "100px" }}
                _hover={{
                  bg: "orange.50",
                  transform: "translateY(-1px)",
                  boxShadow: "md",
                }}
                transition="all 0.2s"
              >
                {t("back")}
              </Button>

              <Button
                colorScheme="orange"
                size={{ base: "sm", sm: "md" }}
                onClick={handleFinish}
                borderRadius="full"
                boxShadow="sm"
                px={{ base: "4", sm: "8" }}
                fontSize={{ base: "xs", sm: "sm" }}
                fontWeight="semibold"
                h={{ base: "32px", sm: "40px" }}
                minW={{ base: "auto", sm: "100px" }}
                bgGradient="linear(to-r, orange.400, orange.500)"
                _hover={{
                  bgGradient: "linear(to-r, orange.500, orange.600)",
                  transform: "translateY(-1px)",
                  boxShadow: "md",
                }}
                transition="all 0.2s"
              >
                {t("finish")}
              </Button>

              <Button
                colorScheme="gray"
                size={{ base: "sm", sm: "md" }}
                rightIcon={<ArrowRight className="h-3.5 w-3.5 sm:h-4 sm:w-4" />}
                borderRadius="full"
                boxShadow="sm"
                px={{ base: "3", sm: "6" }}
                fontSize={{ base: "xs", sm: "sm" }}
                fontWeight="medium"
                h={{ base: "32px", sm: "40px" }}
                minW={{ base: "auto", sm: "100px" }}
                bg="white"
                border="1px solid"
                borderColor="gray.200"
                _hover={{
                  bg: "gray.50",
                  borderColor: "gray.300",
                  transform: "translateY(-1px)",
                  boxShadow: "md",
                }}
                transition="all 0.2s"
                onClick={() => {
                  if (conversationResp) {
                    handleSendMessage({ skipQuestionaire: true });
                  } else {
                    setShowContinue(true);
                    handleSendMessage();
                  }
                }}
              >
                {conversationResp ? t("skip") : t("continue")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewChat;
