import React from 'react';

const StyledSelect = ({ icon: Icon, value, onChange, options, placeholder, error, required }) => {
  return (
    <div className="relative mt-2">
      {Icon && <Icon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />}
      <select
        value={value}
        onChange={onChange}
        className={`block w-full rounded-md border-0 py-1.5 pl-10 pr-4 text-gray-900 ring-1 ring-inset ${
          error ? 'ring-red-300' : 'ring-gray-300'
        } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
        required={required}
      >
        <option value="" disabled hidden>{placeholder}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

export default StyledSelect;