import { Edit3Icon, X } from "lucide-react";

export const EditableSection = ({
  title,
  value,
  options,
  editMode,
  toggleEdit,
  onChange,
  onSave,
  marginBottom,
  paddingBottom,
}) => (
  <div className={`mb-[${marginBottom}px] mb-[${paddingBottom}px] bg-white pt-4 px-6`}>
    <div className="flex justify-between items-center border-b-2 border-gray-200 pb-1">
      <h3 className="text-lg font-semibold">{title}</h3>
      <button
        className="text-gray hover:text-orange-500 px-4 py-2 rounded transition"
        onClick={toggleEdit}
      >
        {editMode ? <X size={16} /> : <Edit3Icon size={16} color="black" />}
      </button>
    </div>
    <div className="mt-2">
      {options.map((option) => (
        <label className="mr-4" key={option}>
          <input
            type="radio"
            name={title}
            value={option}
            checked={value === option}
            onChange={() => onChange(option)}
            disabled={!editMode}
          />{" "}
          {option}
        </label>
      ))}
      {editMode && (
        <button
          className="ml-4 bg-orange-500 text-white px-4 py-2 rounded"
          onClick={onSave}
        >
          Save
        </button>
      )}
    </div>
  </div>
);
