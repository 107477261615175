import { Blinds, Languages, LogOut, Settings } from "lucide-react";
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../utils/LanguageContext";
import { useTranslation } from "react-i18next";
import { removeToken } from "../../utils/auth";
import Logo from "../../assets/logo.png";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  IconButton,
} from "@chakra-ui/react"; // For the dropdown
import { ChevronDownIcon } from "@chakra-ui/icons";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language, changeLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = () => {
    setIsOpen(false);
    removeToken();
    navigate("/login");
  };

  const handleNavigation = (url) => {
    setIsOpen(false);
    navigate(url);
  };

  return (
    <header className="fixed shadow-none md:shadow-lg left-0 right-0 z-50 3xl:container mx-auto px-4 3xl:px-4 md:px-14 lg:py-5 py-3 flex justify-between items-center bg-white text-gray-800">
      {/* Logo */}
      <Link to={"/dashboard"} className="text-2xl font-bold">
        <img src={Logo} width={65} height={65} alt="Logo" />
      </Link>

      {/* Menu Options for Medium and Larger Screens */}
      <div className="hidden md:flex items-center space-x-8">
        {/* Projects Link */}
        <Link to="/dashboard" className="flex items-center space-x-2">
          <Blinds className="w-5 h-5" />
          <span>{t("projects")}</span>
        </Link>

        {/* My Account Link */}
        <Link to="/myAccount" className="flex items-center space-x-2">
          <Settings className="w-5 h-5" />
          <span>{t("account")}</span>
        </Link>

        {/* Language Dropdown */}
        <Menu>
          <MenuButton
            as={IconButton}
            className="hidden md:flex items-center"
            icon={<Languages />}
          >
          </MenuButton>
          <MenuList>
            <MenuItem
              color={language === "eng" ? "orange" : "gray"}
              onClick={() => changeLanguage("eng")}
            >
              🇺🇸 English
            </MenuItem>
            <MenuItem color={language === "spa" ? "orange" : "gray"} onClick={() => changeLanguage("spa")}>
              🇪🇸 Spanish
            </MenuItem>
          </MenuList>
        </Menu>

        {/* Sign Out */}
        <button
          onClick={handleSignOut}
          className="flex items-center space-x-2 text-red-500"
        >
          <LogOut className="w-5 h-5" />
          <span>{t("signout")}</span>
        </button>
      </div>

      {/* Burger Icon for Mobile (Hidden on Medium Screens and Larger) */}
      <div className="md:hidden cursor-pointer" onClick={toggleMenu}>
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16M4 18h16"
          ></path>
        </svg>
      </div>

      {/* Slide-in Side Menu for Small Screens */}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-gray-900 text-white p-4 transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        {/* Close button */}
        <button className="text-white text-2xl" onClick={toggleMenu}>
          &times;
        </button>

        {/* Language Preference for Mobile */}
        <div className="mt-5 text-white flex items-center">
          <h3 className="text-md">{t("Language")}</h3>
          <div className="flex space-x-4 ml-5">
            <button
              className={`${
                language === "eng" ? "text-orange-500" : "text-white"
              }`}
              onClick={() => changeLanguage("eng")}
            >
              ENG
            </button>
            <button
              className={`${
                language === "spa" ? "text-orange-500" : "text-white"
              }`}
              onClick={() => changeLanguage("spa")}
            >
              SPA
            </button>
          </div>
        </div>

        {/* Navigation Links for Mobile */}
        <ul className="mt-10 space-y-4">
          <li>
            <Link
              to="/dashboard"
              className="flex items-center space-x-2"
              onClick={() => handleNavigation("/dashboard")}
            >
              <Blinds className="w-5 h-5" />
              <span>{t("projects")}</span>
            </Link>
          </li>

          <li>
            <Link
              to="/myAccount"
              className="flex items-center space-x-2"
              onClick={() => handleNavigation("/myAccount")}
            >
              <Settings className="w-5 h-5" />
              <span>{t("account")}</span>
            </Link>
          </li>

          <li>
            <button
              className="flex items-center space-x-2 text-red-500"
              onClick={handleSignOut}
            >
              <LogOut className="w-5 h-5" />
              <span>{t("signout")}</span>
            </button>
          </li>
        </ul>
      </div>

      {/* Overlay (closes the menu when clicking outside) */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleMenu}
        />
      )}
    </header>
  );
};

export default Header;
