import React from 'react';
import {Loader } from 'lucide-react';

// Reusable styled button component with hover effect and loading state
const StyledButton = ({ children, isLoading, ...props }) => (
    <button
      className={`w-full flex mb-3 justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-200 ease-in-out transform hover:scale-105 ${isLoading ? 'opacity-75 cursor-not-allowed' : ''}`}
      disabled={isLoading}
      {...props}
    >
      {isLoading ? (
        <>
          <Loader className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
          Processing...
        </>
      ) : (
        children
      )}
    </button>
);


export default StyledButton;