import React from "react";
import Logo from "../../assets/logo.png";

// Wrapper component for auth forms with subtle background animation
const AuthFormWrapper = ({ children, title, subtitle, className }) => (
  <div
    className={`min-h-screen flex items-center justify-center bg-gradient-to-r from-indigo-50 via-purple-50 to-pink-50 animate-gradient-x py-12 px-4 sm:px-6 lg:px-8 ${className}`}
  >
    <div className="max-w-md w-full space-y-8 bg-white px-8 pt-7 pb-10 sm:px-10 rounded-xl shadow-lg transition-all duration-300 hover:shadow-2xl">
      <div className="text-center flex flex-col items-center">
        <div className="flex-col flex justify-center items-center self-center mb-4">
          <img className="self-center" src={Logo} width={120} height={120} alt="Logo" />
        </div>
        <h2 className="text-3xl font-extrabold text-gray-900 mb-2">{title}</h2>
        {subtitle && <p className="text-sm text-gray-600">{subtitle}</p>}
      </div>
      {children}
    </div>
  </div>
);

export default AuthFormWrapper;
