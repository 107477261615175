import { ChatIcon } from "@chakra-ui/icons";
import { Flex, Box, Text, IconButton, Spinner } from "@chakra-ui/react";
import { EllipsisVertical, TrashIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import JumpToSection from "./JumpToSection";
import JumpToSectionLG from "./JumpToSectionLG";
import axios from "axios";
import { ConfirmationModal } from "../../../ui/confirmationModal";
import { useState } from "react";

export const ProjectOverview = ({ projectDetails, chatDetails, setModalOpen, selectedSection, setSelectedSection }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {id: projectId} = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Destructure projectDetails for cleaner code
  const { name, date_created, last_modified, id } = projectDetails || {};

  // Memoized date formatting to avoid recalculating on each render
  const formattedCreatedDate = date_created
    ? new Date(date_created).toLocaleDateString()
    : "";
  const formattedModifiedDate = last_modified
    ? new Date(last_modified).toLocaleDateString()
    : "";

  const handleDeleteProject = async () => {
    setIsDeleting(true);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            accept: "application/json",
          },
        }
      );
      setIsDeleting(false);
      if (response.status === 200 || response.status === 204) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error("Error deleting project:", error);
      // You may want to add error handling/toast notification here
      setIsDeleting(false);
    }
  };

  return (
    <Box bg="white" px={6} py={4} boxShadow="lg">
      {/* Header Section */}
      <Flex justify="space-between" align="center" mb={3}>
        <Text fontSize="2xl" fontWeight="semibold" color="gray.800">
          {name}
        </Text>
        

        {/* Icon Buttons */}
        <Flex align="center">
        <JumpToSectionLG
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            chatDetails={chatDetails}
          />
          <IconButton
            aria-label="Chat"
            icon={<ChatIcon fontSize={18} color="orange" />}
            onClick={() => navigate(`/chat/${id}`, { state: { projectData: projectDetails } })}
            variant="ghost"
            _hover={{ bg: "gray.100" }}
            rounded="full"
            marginLeft={2}
          />
          <IconButton
            aria-label="Delete Project"
            icon={<TrashIcon className="text-red-500 h-5 w-5" />}
            onClick={() => setIsModalOpen(true)}
            variant="ghost"
            _hover={{ bg: "red.50" }}
            rounded="full"
          />
          <IconButton
            aria-label="More Options"
            icon={<EllipsisVertical className="text-gray-600" />}
            onClick={() => setModalOpen(true)}
            variant="ghost"
            _hover={{ bg: "gray.100" }}
            rounded="full"
          />
        </Flex>
      </Flex>

      {/* Project Details */}
      <Box color="gray.600">
        <Text>
          {t("projectStarted")}: {formattedCreatedDate}
        </Text>
        <Text>
          {t("lastModified")}: {formattedModifiedDate}
        </Text>
      </Box>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleDeleteProject}
        title="Delete"
        description="Are you sure you want to delete this project?"
        actionLabel={isDeleting ? (
          <Flex align="center">
            <Spinner size="sm" mr={2} />
            Deleting...
          </Flex>
        ) : "Delete"}
        cancelLabel="Cancel"
      />
    </Box>
  );
};
