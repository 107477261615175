import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Lock, ArrowRight} from 'lucide-react';
import AuthFormWrapper from './authFormWrapper';
import StyledButton from '../ui/styledButton';
import StyledInput from '../ui/styledInput';


// Password Reset Confirmation Screen
const PasswordResetConfirmScreen = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResetConfirm = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      // Simulate API call
      setTimeout(() => {
        setIsLoading(false);
        navigate('/login');
      }, 2000);
    }
  };

  return (
    <AuthFormWrapper title="Set new password">
      <form className="mt-8 space-y-6" onSubmit={handleResetConfirm}>
        <div>
          <StyledInput
            icon={Lock}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New password"
            error={errors.password}
            required
          />
          <StyledInput
            icon={Lock}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm new password"
            error={errors.confirmPassword}
            required
          />
        </div>
        <div>
          <StyledButton type="submit" isLoading={isLoading}>
            Set new password
            <ArrowRight className="ml-2 h-4 w-4" />
          </StyledButton>
        </div>
      </form>
    </AuthFormWrapper>
  );
};

export default PasswordResetConfirmScreen;