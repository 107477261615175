import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useShowToast from "../../utils/useShowToast";

export function ChangePasswordModal({ isModalOpen, setIsModalOpen }) {
  const { t } = useTranslation();
  const toast = useToast();
  const showToast = useShowToast();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [resetPassLoading, setResetPassLoading] = useState(false);

  const validateForm = () => {
    const errors = {};
    if (!oldPassword) errors.oldPassword = "Old password is required";
    if (!newPassword) errors.newPassword = "New password is required";
    if (newPassword !== confirmNewPassword)
      errors.confirmNewPassword = "Passwords do not match";
    return errors;
  };

  const handleChangePasswordSubmit = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setResetPassLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/change-password/`,
        {
          old_password: oldPassword,
          new_password: newPassword,
          new_password2: confirmNewPassword,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setResetPassLoading(false);
        showToast("Password reset successful!", "success");
        setIsModalOpen(false); // Close modal on success
      }
    } catch (error) {
      setResetPassLoading(false);
      showToast("Something went wrong. Please try again.", "error");
    }
  };
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("changePassword")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl isInvalid={formErrors.oldPassword} mb={4}>
            <FormLabel>{t("oldPassword")}</FormLabel>
            <Input
              type="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              placeholder={t("enterOldPassword")}
            />
            {formErrors.oldPassword && (
              <FormErrorMessage>{formErrors.oldPassword}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formErrors.newPassword} mb={4}>
            <FormLabel>{t("newPassword")}</FormLabel>
            <Input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={t("enterNewPassword")}
            />
            {formErrors.newPassword && (
              <FormErrorMessage>{formErrors.newPassword}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={formErrors.confirmNewPassword} mb={4}>
            <FormLabel>{t("confirmNewPassword")}</FormLabel>
            <Input
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder={t("confirmNewPassword")}
            />
            {formErrors.confirmNewPassword && (
              <FormErrorMessage>
                {formErrors.confirmNewPassword}
              </FormErrorMessage>
            )}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="orange"
            mr={3}
            onClick={handleChangePasswordSubmit}
            isLoading={resetPassLoading}
          >
            {t("changePassword")}
          </Button>
          <Button variant="outline" onClick={() => setIsModalOpen(false)}>
            {t("cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
