import { useState } from 'react';
import Cookies from 'js-cookie';



export const useCookies = () => {
  const [cookieValue, setCookieValue] = useState(undefined);

  // Get a cookie by name
  const getCookie = (name) => {
    const value = Cookies.get(name);
    setCookieValue(value);
    return value;
  };

  // Set a cookie
  const setCookie = (
    name,
    value,
    options
  )=> {
    Cookies.set(name, value, options);
    setCookieValue(value);
  };

  // Remove a cookie
  const removeCookie = (name) => {
    Cookies.remove(name);
    setCookieValue(undefined);
  };

  return {
    cookieValue,
    getCookie,
    setCookie,
    removeCookie,
  };
};
