import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const JumpToSection = ({ chatDetails, selectedSection, setSelectedSection }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (value) => {
    setSelectedSection(value);
    setIsOpen(false); // Close the dropdown after selection
  };
  const project_setup = t("projectSetup");
  const interiot_sections = t("interiorSections");
  return (
    <div className="relative">
      {/* Label and Desktop View Select */}
      {/* <div className="flex items-center space-x-2">
        <label
          htmlFor="section-select"
          className="text-orange-500 hidden md:block"
        >
          Jump to
        </label>
        <select
          id="section-select"
          className="p-2 rounded border border-gray-300 hidden md:block"
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
        >
          <option value="setup">Project Setup</option>
          <option value="interior">Interior Sections</option>
        </select>
      </div> */}

      {/* Mobile Fixed Bottom View */}
      <div className="fixed md:hidden border-r-8 bottom-0 grid grid-cols-[20%,80%] space-x-0 left-0 w-full align-middle items-center bg-gray-500 p-3 h-14  shadow-lg z-10">
        <label className="block text-white ml-2 font-light">
          {t("jumpTo")}
        </label>
        <div className="relative">
          <div
            onClick={toggleDropdown}
            className="w-full p-3 border rounded-md border-gray-300 bg-white cursor-pointer flex justify-between items-center shadow-md h-8"
          >
            <span className="text-gray-600">
              {selectedSection === "setup"
                ? `${project_setup}`
                : `${interiot_sections}`}
            </span>
            <span className="text-gray-500">{isOpen ? "▲" : "▼"}</span>
          </div>
          {isOpen && (
            <div className="absolute bottom-full left-0 w-full bg-white border border-gray-300 shadow-lg z-20 rounded-lg">
              <div
                onClick={() => handleOptionSelect("setup")}
                className={`p-3 cursor-pointer hover:bg-gray-200 rounded-t-lg ${
                  selectedSection === "setup" ? "bg-orange-400 text-white" : ""
                }`}
              >
                {t("projectSetup")}
              </div>
              {Object.keys(chatDetails || {}).map((section, index, array) => (
                <div
                  key={section}
                  onClick={() => handleOptionSelect(section)}
                  className={`p-3 cursor-pointer hover:bg-gray-200 ${
                    index === array.length - 1 ? "rounded-b-lg" : ""
                  } ${
                    selectedSection === section ? "bg-orange-400 text-white" : ""
                  }`}
                >
                  {t(section)}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JumpToSection;
