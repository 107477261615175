import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, AlertDescription } from "../../ui/alert";
import { EditableSection } from "./components/EditableSection";
import InteriorSection from "./components/InteriorSection";
import JumpToSection from "./components/JumpToSection";
import { ProjectActionsModal } from "./components/ProjectActionModal";
import { ProjectOverview } from "./components/ProjectOverview";
import { useTranslation } from "react-i18next";
import { isAuthenticated } from "../../../utils/auth";
import useHandleSessionExpired from "../../../utils/useHandleSessionExpired";
import { Toast } from "@chakra-ui/react";
import useShowToast from "../../../utils/useShowToast";
import useChatAssistant from '../../../hooks/useChatAssistant';

import { Building2, Calendar, Maximize2, Bed, Bath, Home } from "lucide-react";

const ProjectDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const showToast = useShowToast();
  const handleSessionExpired = useHandleSessionExpired();
  const [editMode, setEditMode] = useState({ quality: false, color: false });
  const [projectDetails, setProjectDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ quality: "", colorTemplate: "" });
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState("setup"); 
  const [reportLoading, setReportLoading] = useState(false);

  const { chat, isLoading: chatLoading, error: chatError } = useChatAssistant();
  const [reportData, setReportData] = useState(null);
  console.log(reportData);

  const fetchProjectDetails = useCallback(async () => {
    if (!projectDetails) {
      setLoading(true);
      let projectData = null;
      let chatData = null;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/projects/${id}/`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );

        if (response.status === 401) {
          handleSessionExpired();
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch project details");
        }

        projectData = await response.json();
      } catch (err) {
        console.error("Error fetching project details:", err.message);
      }

      try {
        const chatDetails = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/chat-questionaire/?id=${id}`,
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        chatData = chatDetails.data;
      } catch (err) {
        console.error("Error fetching chat details:", err.message);
      }

      if (projectData || chatData) {
        setProjectDetails({
          projectDetails: projectData,
          chatDetails: chatData || {}
        });
        if (projectData) {
          setFormData({
            quality: projectData.quality,
            colorTemplate: projectData.color_template,
          });
        }
      } else {
        setError("Failed to fetch both project and chat details");
      }
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (id) {
      fetchProjectDetails();
    }
  }, [id, fetchProjectDetails]);


  const updateProjectDetails = async () => {
    try {
      const updatedPayload = {
        name: projectDetails.projectDetails.name,
        quality: formData.quality,
        color_template: formData.colorTemplate,
      };

      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/projects/${id}/`,
        updatedPayload,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      setProjectDetails((prev) => ({
        ...prev,
        quality: formData.quality,
        color_template: formData.colorTemplate,
      }));
      showToast('Project updated successfully','success');
    } catch (err) {
      setError("Failed to update project details");
      showToast('Something went wrong','warning');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-orange-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }

  const generateReport = async () => {
    setReportLoading(true);
    const projectJson = JSON.stringify(projectDetails);
    const prompt = `Generate a report from this project detail JSON and return a JSON with 'headers' and 'rows' that 
    can be used to generate a CSV file. The 'headers' should be an array of column names, and 'rows' should be an 
    array of objects where each object represents a row with key-value pairs matching the headers: 
    
    !!IMPORTANT NOTE!! DO NOT GIVE ANY DETAILS IN YOUR ANSWER JUST RETURN PLAIN JSON. HERE IS THE DETAILS FOLLOW THESE TO GENERATE THE BID REPORT.
    MAKE SURE TO INCLUDE THE PRICE OF EACH ITEM. 

    ${projectJson}
    
    Example OUTPUT. 
    [ { "Location": "Exterior", "Image": "URL_to_image1", "Trade": "Roofing", "Category": "Replacement", "ScopeVideo": "URL_to_scope_video1", 
      "Description": "Replace the roof with high-durability asphalt shingles in gray.", "SKUReference": "ROOF1234", "UnitType": "square feet", 
      "TotalUnits": 1000, "ItemCost": 5, "LaborCost": 3000, "TotalCost": 8000 }, { "Location": "Exterior", "Image": "URL_to_image2", "Trade": 
      "Demolition", "Category": "Removal", "ScopeVideo": "URL_to_scope_video2", "Description": "Demolish the old porch and remove debris.", 
    "SKUReference": "DEMO5678", "UnitType": "hours", "TotalUnits": 10, "ItemCost": 0, "LaborCost": 500, "TotalCost": 500 }]`;
    
    try {
      const response = await chat(prompt);
      if (response) {
        const jsonMatch = response.match(/```json\n([\s\S]*?)\n```/);
        if (jsonMatch && jsonMatch[1]) {
          const parsedResponse = JSON.parse(jsonMatch[1]);
          setReportData(parsedResponse);
          showToast('Report generated successfully', 'success');
          downloadCsv(parsedResponse);
        } else {
          throw new Error('Invalid JSON format in response');
        }
      } else {
        throw new Error('Empty response from chat');
      }
    } catch (error) {
      console.error('Error generating report:', error);
      showToast('Error generating report', 'error');
    } finally {
      setReportLoading(false);
    }
  };

  const downloadCsv = (data) => {
    if (!data) return;

    let headers, csvContent;

    if (data.headers && data.rows) {
      headers = data.headers;
      csvContent = [
        headers.join(','),
        ...data.rows.map(row => headers.map(header => row[header] || '').join(','))
      ].join('\n');
    } else if (data.columns) {
      headers = data.columns;
      csvContent = [
        headers.join(','),
        ...data.columns[0].map((_, rowIndex) =>
          headers.map(header => data.columns[headers.indexOf(header)][rowIndex] || '').join(',')
        )
      ].join('\n');
    } else {
      headers = data && data.length > 0 ? Object.keys(data[0]) : [];
      csvContent = [
        headers.join(','),
        ...data.map(row => headers.map(header => row[header] || '').join(','))
      ].join('\n');
    }

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${projectDetails.projectDetails.name}_report.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  

  return (
    <div className={`container mt-3 mx-auto px-4 py-8 bg-gray-50 min-h-screen `}>
      <div
        className={` bg-slate-100 min-h-[7rem] ${
          isAuthenticated() ? "pt-[3rem]  md:pt-[50px] lg:pt-[100px]" : "pt-0"
        }`}
      >
        <h1 className="text-3xl p-6 mb-3  font-semibold">
          <span className="text-gray-800">{t("project")}</span>&nbsp;
          <span className="text-orange-500">{t("details")}</span>
        </h1>
      </div>
      <div className="pb-10 bg-white ">
      <JumpToSection
        chatDetails = {projectDetails.chatDetails}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />
      {/* Project Setup Sections */}
      <ProjectOverview
        projectDetails={projectDetails.projectDetails}
        chatDetails = {projectDetails.chatDetails}
        setModalOpen={setModalOpen}
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />

      {/* sections based on selected option */}
      {selectedSection === "setup" ? (
        <>
          <AddressDetails projectDetails={projectDetails} />
          <EditableSection
            title={t("qualityMaterial")}
            value={formData.quality}
            options={["A", "B"]}
            editMode={editMode.quality}
            toggleEdit={() =>
              setEditMode({ ...editMode, quality: !editMode.quality })
            }
            onChange={(newValue) =>
              setFormData({ ...formData, quality: newValue })
            }
            onSave={()=>{updateProjectDetails();setEditMode({ ...editMode, quality: !editMode.quality })}}
            marginBottom={0}
          />
          <EditableSection
            title={t("colorTemplate")}
            value={formData.colorTemplate}
            options={["Grays", "Beige"]}
            editMode={editMode.color}
            toggleEdit={() =>
              setEditMode({ ...editMode, color: !editMode.color })
            }
            onChange={(newValue) =>
              setFormData({ ...formData, colorTemplate: newValue })
            }
            onSave={()=>{updateProjectDetails();setEditMode({ ...editMode, color: !editMode.color })}}
            marginBottom={20}
            paddingBottom={20}
            />
        </>
      ) : (
        <>
          {Object.entries(projectDetails?.chatDetails?.[selectedSection] || {}).map(([key, value]) => {
            if(value && (value?.content !== "NA" || value?.url)){
            return (
            <InteriorSection
              key={key}
              title={t(key)}
              description={value?.content || "N/A"}
              videoUrl={value?.url || null}
              chatDetails={projectDetails.chatDetails || []}
            />
            )
          }
          })}
        </>
      )}

      <ProjectActionsModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        projectName={projectDetails?.projectDetails?.name}
        generateReport={generateReport}
        reportLoading={reportLoading}
      />
      </div>
      
    </div>
  );
};

const AddressDetails = ({ projectDetails }) => {
  const { t } = useTranslation();
  
  const stats = [
    { 
      label: t("yearBuilt"), 
      value: projectDetails?.projectDetails?.year_built,
      icon: Calendar
    },
    { 
      label: t("squareFootage"), 
      value: `${projectDetails?.projectDetails?.square_footage} sq. ft.`,
      icon: Maximize2
    },
    { 
      label: t("bedrooms"), 
      value: projectDetails?.projectDetails?.total_bedrooms,
      icon: Bed
    },
    { 
      label: t("bathrooms"), 
      value: projectDetails?.projectDetails?.total_bathrooms,
      icon: Bath
    },
    { 
      label: t("propertyType"), 
      value: projectDetails?.projectDetails?.property_type,
      icon: Home
    }
  ];
  
  return (
    <div className="bg-white mt-3 mb-5 rounded-lg shadow-md border border-gray-100 group hover:shadow-lg transition-all duration-300">
      <div className="border-b border-gray-100 p-4 flex items-center gap-2">
        <Building2 className="h-5 w-5 text-gray-500" />
        <h3 className="text-lg font-semibold text-gray-800">
          {t("addressDetails")}
        </h3>
      </div>
      <div className="p-6">
        <p className="text-2xl font-semibold text-gray-900 mb-4 group-hover:text-orange-500 transition-colors duration-300">
          {projectDetails?.projectDetails?.address || "ABC, Str#7, XYZ"}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {stats.map((item, index) => {
            const Icon = item.icon;
            return (
              <div key={index} 
                   className="bg-gray-50 p-4 rounded-lg hover:bg-orange-50 transition-colors duration-300 group/stat">
                <div className="flex items-center gap-2 mb-1">
                  <Icon className="h-4 w-4 text-gray-400 group-hover/stat:text-orange-500 transition-colors duration-300" />
                  <p className="text-sm text-gray-500">{item.label}</p>
                </div>
                <p className="text-lg font-medium text-gray-900 group-hover/stat:text-orange-600 transition-colors duration-300">
                  {item.value || "N/A"}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
