import { useEffect, useState } from "react";
import "./styles.css";
import { Edit3Icon } from "lucide-react";
import axios from "axios";
import { useParams } from "react-router-dom";

const InteriorSection = ({ title, description, videoUrl, marginBottom, chatDetails }) => {
  const { id: projectId } = useParams();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEditing, setIsEditing] = useState({
    property: null,
    content: null,
  });
  const [loading, setLoading] = useState(true);
  const [thumbnailUrl, setThumbnailUrl] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAR8AAACvCAMAAADzNCq+AAAAMFBMVEX5+fnU1NTc3NzR0dH19fXW1tb4+Pj09PTY2Njq6urm5ubx8fHu7u7i4uLb29vf39/ZFNdeAAAFHUlEUgAAAABJRU5ErkJggg=="
  );
  const extractVideoId = (url) => {
    if (!url) return "";
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2].length === 11 ? match[2] : null;
  };
  const videoID = extractVideoId(videoUrl);

  useEffect(() => {
    if (videoUrl) {
      const video = document.createElement("video");
      video.src = videoUrl;
      video.crossOrigin = "anonymous";
      video.onloadeddata = () => {
        video.currentTime = 1; // Capture thumbnail at 1 second
      };
      video.onseeked = () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");
        context?.drawImage(video, 0, 0, canvas.width, canvas.height);
        const thumbnail = canvas.toDataURL("image/png");
        setThumbnailUrl(thumbnail);
      };
    }
  }, [videoUrl]);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  const handleSave = async () => {
    setIsEditing({ property: null, content: null });
    try {
      const updatedPayload = {
        ...chatDetails,
        interior: {
          ...chatDetails.interior,
          [title.toLowerCase().replace(/ /g, "_")]: {
            ...chatDetails.interior[title.toLowerCase().replace(/ /g, "_")],
            content: isEditing.content,
          },
        },
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/chat-questionaire/?id=${projectId}`,
        updatedPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        console.log("Update successful");
      } else {
        console.error("Update failed");
      }
    } catch (error) {
      console.error("Error updating content:", error);
    }
  };

  return (
    <div className={`mb-4 bg-white px-6 ${marginBottom ? "mb-20" : "pb-0"}`}>
      {/* Headline with Edit Icon */}
      <div className="flex items-center justify-between mb-1">
        <h3 className="text-lg font-semibold text-gray-800 capitalize">{title.replaceAll("_"," ")}</h3>
        {isEditing.property !== title ? (
          <button
            className="text-gray-400 hover:text-gray-600 transition-colors"
            onClick={() =>
              setIsEditing({ property: title, content: description })
            }
          >
            <i className="fas fa-edit"></i>{" "}
            <Edit3Icon size={16} color="black" />
          </button>
        ) : (
          <button
            className="ml-4 bg-orange-500 text-white px-4 py-2 rounded"
            onClick={handleSave}
          >
            Save
          </button>
        )}
      </div>
      <hr className="mb-2 border-gray-400" />
      <div className="md:h-4" />
      {/* Two-column layout */}
      <div className="grid grid-cols-[62%,35%] md:grid-cols-[62%,36%] gap-3  md:gap-6">
        {/* <p className="text-gray-700 leading-relaxed">{description}</p> */}
        <textarea
          className="w-full sm:h-60 p-2 text-gray-700 border-gray-400 rounded-lg leading-relaxed border resize-none disabled:bg-gray-50"
          value={
            isEditing.property === title ? isEditing.content : description
          }
          disabled={isEditing.property !== title}
          onChange={(e) =>
            setIsEditing({ ...isEditing, content: e.target.value })
          }
        />

        <div className="relative flex justify-center items-center">
          {isPlaying ? (
            <>
              {loading && (
                <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 rounded-lg">
                  <span className="loader"></span>{" "}
                </div>
              )}
              <iframe
                width="100%"
                className="h-60"
                src={
                  videoUrl || `https://www.youtube.com/embed/${videoID}?autoplay=1` || ""
                }
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                onLoad={() => setLoading(false)}
              ></iframe>
            </>
          ) : videoUrl ? (
            <>
              {/* Thumbnail Image */}
              <img
                src={thumbnailUrl}
                alt={`${title} video`}
                className="w-full max-h-60 rounded-lg object-cover shadow-md"
              />
              {/* Play Button Overlay */}
              <div
                className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-50 rounded-lg cursor-pointer"
                onClick={handlePlay}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-16 w-16 text-white"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M6 4.318v11.364L15 10 6 4.318z" />
                </svg>
              </div>
            </>
          ) : (
            <div className="w-full h-60 bg-gray-200 rounded-lg flex items-center justify-center">No Video</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InteriorSection;
