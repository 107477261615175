const ProgressBar = ({ percentage }) => (
  <div className="flex items-center space-x-2 w-full">
    <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
      <div
        className="h-full transition-all duration-300 rounded-full"
        style={{
          width: `${percentage}%`,
          backgroundColor: percentage === 100 ? "#10B981" : "#F59E0B",
        }}
      />
    </div>
    <span className="text-xs text-gray-600 whitespace-nowrap">{percentage.toFixed(0)}%</span>
  </div>
);
export default ProgressBar;
