import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Mail, ArrowRight } from "lucide-react";
import { Alert, AlertDescription } from "../ui/alert";
import AuthFormWrapper from "./authFormWrapper";
import StyledButton from "../ui/styledButton";
import StyledInput from "../ui/styledInput";
import axios from "axios";
import useShowToast from "../../utils/useShowToast";

// Password Reset Request Screen
const PasswordResetRequestScreen = () => {
  const showToast = useShowToast();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResetRequest = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/auth/password-reset/`,
          { email: email }
        );
        if (response.status === 200 || response.status === 201) {
          showToast(
            "Password reset link has been sent to your email.",
            "success"
          );
          setIsLoading(false);
        }
      } catch (error) {
        showToast("Something went wrong!", "warning");
        setIsLoading(false);
      }
    }
  };

  return (
    <AuthFormWrapper
      title="Reset your password"
      subtitle="Enter your email to receive a reset link"
    >
      <form className="mt-8 space-y-6" onSubmit={handleResetRequest}>
        <div>
          <StyledInput
            icon={Mail}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            error={errors.email}
            required
          />
        </div>
        <div>
          <StyledButton type="submit" isLoading={isLoading}>
            Send reset link
            <ArrowRight className="ml-2 h-4 w-4" />
          </StyledButton>
        </div>
      </form>

      <div className="text-center mt-6">
        <Link
          to="/login"
          className="text-sm font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
        >
          Back to login
        </Link>
      </div>
    </AuthFormWrapper>
  );
};

export default PasswordResetRequestScreen;
