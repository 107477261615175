import React from "react";

// Simple function to join classNames
const cn = (...classes) => classes.filter(Boolean).join(" ");

const Alert = React.forwardRef(({ className, variant = "default", ...props }, ref) => {
  const baseStyles = "relative w-full rounded-lg border p-4";
  const variantStyles = {
    default: "bg-gray-100 text-gray-900 border-gray-200",
    destructive: "bg-red-100 text-red-900 border-red-200",
  };

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(baseStyles, variantStyles[variant], className)}
      {...props}
    />
  );
});

Alert.displayName = "Alert";

const AlertDescription = React.forwardRef(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm", className)}
    {...props}
  />
));

AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription };